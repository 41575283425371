import React, { useState, useEffect } from 'react';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'material-react-toastify';
import { msalInstance } from './config/sso';
import 'material-react-toastify/dist/ReactToastify.css';

// This import is required for the collection editor page
import 'bootstrap/dist/css/bootstrap.min.css';

import { QueryClient, QueryClientProvider } from 'react-query';
import { BenchmarkPerformanceReport } from './pages/benchmarks/clients/reports/performance';
import BenchmarkChartsComparison from './pages/public/ComparisonChart';
import BenchmarksRadialChart from './pages/public/RadialChart';
import {
  ConfigCustomDashList,
  CustomDashboard,
  NewDashboard,
  QueryExplorer,
  ReportAcquisition,
  ReportDebug,
  ReportDetail,
  ReportEngagement,
  ReportList,
  ReportOverview,
  ReportRetention,
} from './pages/reports';

// Base Imports
// ------------------------------------------------------------------//

import {
  AdminProvider,
  getEnv,
  useMountedEffect,
  SessionTimeoutModal,
  DeloitteLogin,
  BaseAdmin,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';

import {
  BenchmarkIndustryList,
  BenchmarkIndustryNew,
} from './pages/benchmarks/industries';
import {
  BenchmarkSectorsList,
  BenchmarkSectorsNew,
} from './pages/benchmarks/sectors';
import {
  BenchmarkSubSectorsList,
  BenchmarkSubSectorsNew,
} from './pages/benchmarks/subSectors';
import { BenchmarkVendorDetail } from './pages/benchmarks/vendors';
import { BenchmarkActions } from './pages/benchmarks/actions';
import {
  BenchmarkBrowseByIndustry,
  BenchmarkClientDetail,
  BenchmarkClientEdit,
  BenchmarkClientList,
  BenchmarkClientListInactive,
  BenchmarkClientNew,
  BenchmarkClientPageDetail,
  BenchmarkClientPageEdit,
  BenchmarkClientPageNew,
  BenchmarkClientRunDetail,
  BenchmarkClientScorecard,
  BenchmarkProspectNew,
} from './pages/benchmarks/clients';
import { BenchmarkClientCompetitorsNew } from './pages/benchmarks/clients/competitors';

const BenchmarkApproachabilityReport = React.lazy(() =>
  import('./pages/benchmarks/clients/reports/approachability').then(
    (module) => ({
      default: module.BenchmarkApproachabilityReport,
    })
  )
);

const BenchmarkCompetitorReport = React.lazy(() =>
  import(
    './pages/benchmarks/clients/reports/competitors/competitorReport'
  ).then((module) => ({
    default: module.default,
  }))
);

const BenchmarkOwnershipReport = React.lazy(() =>
  import('./pages/benchmarks/clients/reports/ownership').then((module) => ({
    default: module.BenchmarkOwnershipReport,
  }))
);

const BenchmarkSearchReport = React.lazy(() =>
  import('./pages/benchmarks/clients/reports/search/searchReport').then(
    (module) => ({
      default: module.default,
    })
  )
);

const BenchmarkSlides = React.lazy(() =>
  import('./pages/benchmarks/clients/slides/slides').then((module) => ({
    default: module.default,
  }))
);

import { Route } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import SSOLogin from './pages/auth/ssoLogin';

const App = () => {
  const [sidebarRolebaseOptions, setSidebarRolebaseOptions] = useState<any>();
  const [sidebarReady, setSidebarReady] = useState<any>(false);
  const [sidebarFixedOptions, setSidebarFixedOptions] = useState<any>();

  const queryClient = new QueryClient();

  useMountedEffect((mounted: boolean) => {
    // eslint-disable-next-line
    (async () => {
      const { roleBased, defaultFixed } = await import('./config/sidebar');
      setSidebarRolebaseOptions(roleBased);
      setSidebarFixedOptions(defaultFixed);
      setSidebarReady(true);
    })();

    // eslint-disable-next-line no-param-reassign
    return () => (mounted = false);
  }, []);

  const theme = createTheme({
    typography: {
      fontFamily: [
        '"Bw Modelica"',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      h4: {
        fontWeight: 900,
      },
    },
    palette: {
      background: {
        default: '#fff',
      },
      primary: {
        main: '#0070eb',
        light: '#e0efff',
      },
      secondary: {
        main: '#bbdafd',
      },
    },
  });

  theme.components = {
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          textAlign: 'left',
          backgroundColor: theme.palette.common.white,
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        },
        selected: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[800],
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    // Name of the component ⚛️
    MuiButtonBase: {
      defaultProps: {
        style: {
          textDecoration: 'none',
        },
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0, // square corners
          textTransform: 'none', // removes uppercase transformation
          textDecoration: 'none',
        },
        containedPrimary: {
          '&:hover': {
            // changes colors for hover state
            backgroundColor: '#bbdafd', // from mui4: theme.palette.secondary.main,
            color: '#0070eb', // from mui4: theme.palette.primary.dark
          },
        },
        containedSecondary: {
          fontWeight: 700, // makes text bold
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'primary',
        underline: 'none',
      },
    },
  };

  // We are using this so that we can work with both SSO auth and also
  // when we are local and want to test without SSO being required
  const AuthWrapper = ({ children }) => {
    const isProvider = getEnv('REACT_APP_AUTH_METHOD') || 'password';
    if (isProvider === 'provider' && msalInstance) {
      return (
        <>
          <MsalProvider instance={msalInstance}>
            <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <SSOLogin />
            </UnauthenticatedTemplate>
          </MsalProvider>
        </>
      );
    } else {
      return <>{children}</>;
    }
  };

  return (
    <>
      {msalInstance && (
        <AuthWrapper>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {sidebarReady && (
                <AdminProvider
                  config={{
                    apiRoot:
                      getEnv('REACT_APP_API_ROOT') ||
                      'https://admin.hux-staging.sandbox.ddapps.xyz',
                    debug: getEnv('REACT_APP_DEBUG') || false,
                    startPage: getEnv('REACT_APP_START_PAGE') || '/',
                    sidebar: {
                      fixed: sidebarFixedOptions,
                      roleBasedOptions: sidebarRolebaseOptions,
                    },
                    authMethod: {
                      type: getEnv('REACT_APP_AUTH_METHOD') || 'password',
                    },
                  }}
                >
                  <SessionTimeoutModal />

                  <BaseAdmin>
                    <>
                      <Route path='/' Component={BenchmarkClientList} />
                      <Route
                        path='/public/chart/comparison'
                        Component={BenchmarkChartsComparison}
                      />
                      <Route
                        path='/public/chart/radial'
                        Component={BenchmarksRadialChart}
                      />
                      <Route
                        path='/benchmarks/actions'
                        Component={BenchmarkActions}
                      />
                      <Route
                        path='/benchmarks/browse/industry/:industryName'
                        Component={BenchmarkBrowseByIndustry}
                      />
                      <Route
                        path='/benchmarks/vendors/:vendorId'
                        Component={BenchmarkVendorDetail}
                      />
                      <Route
                        path='/benchmarks/industries/new'
                        Component={BenchmarkIndustryNew}
                      />
                      <Route
                        path='/benchmarks/industries'
                        Component={BenchmarkIndustryList}
                      />
                      <Route
                        path='/benchmarks/sectors/new'
                        Component={BenchmarkSectorsNew}
                      />
                      <Route
                        path='/benchmarks/sectors'
                        Component={BenchmarkSectorsList}
                      />
                      <Route
                        path='/benchmarks/sub-sectors/new'
                        Component={BenchmarkSubSectorsNew}
                      />
                      <Route
                        path='/benchmarks/sub-sectors'
                        Component={BenchmarkSubSectorsList}
                      />
                      <Route
                        path='/benchmarks/clients/scorecard'
                        Component={BenchmarkClientScorecard}
                      />
                      <Route
                        path='/benchmarks/clients/new'
                        Component={BenchmarkClientNew}
                      />

                      <Route
                        path='/benchmarks/clients/:clientId/prospects/new'
                        Component={BenchmarkProspectNew}
                      />

                      <Route
                        path='/benchmarks/clients/:clientId/report/slides'
                        Component={BenchmarkSlides}
                      />

                      <Route
                        path='/benchmarks/clients/:clientId/report/competitor'
                        Component={BenchmarkCompetitorReport}
                      />

                      <Route
                        path='/benchmarks/clients/:clientId/report/performance'
                        Component={BenchmarkPerformanceReport}
                      />

                      <Route
                        path='/benchmarks/clients/:clientId/report/approachability'
                        Component={BenchmarkApproachabilityReport}
                      />

                      <Route
                        path='/benchmarks/clients/:clientId/report/ownership'
                        Component={BenchmarkOwnershipReport}
                      />

                      <Route
                        path='/benchmarks/clients/:clientId/report/search'
                        Component={BenchmarkSearchReport}
                      />

                      <Route
                        path='/benchmarks/clients/:clientId/pages/new'
                        Component={BenchmarkClientPageNew}
                      />
                      <Route
                        path='/benchmarks/clients/:clientId/pages/:pageId/edit'
                        Component={BenchmarkClientPageEdit}
                      />
                      <Route
                        path='/benchmarks/clients/:clientId/pages/:pageId'
                        Component={BenchmarkClientPageDetail}
                      />
                      <Route
                        path='/benchmarks/clients/:clientId/runs/:runId'
                        Component={BenchmarkClientRunDetail}
                      />

                      <Route
                        path='/benchmarks/clients/:clientId/edit'
                        Component={BenchmarkClientEdit}
                      />

                      <Route
                        path='/benchmarks/clients/:clientId/competitors/new'
                        Component={BenchmarkClientCompetitorsNew}
                      />

                      <Route
                        path='/benchmarks/clients/inactive'
                        Component={BenchmarkClientListInactive}
                      />

                      <Route
                        path='/benchmarks/clients/:clientId'
                        Component={BenchmarkClientDetail}
                      />

                      <Route
                        path='/benchmarks/clients'
                        Component={BenchmarkClientList}
                      />

                      {/* reports */}

                      <Route
                        path='/reports/customdashboard/:id'
                        Component={CustomDashboard}
                      />

                      <Route path='/reports/detail' Component={ReportDetail} />

                      <Route
                        path='/reports/overview'
                        Component={ReportOverview}
                      />

                      <Route path='/reports/debug' Component={ReportDebug} />
                      <Route
                        path='/reports/acquisition'
                        Component={ReportAcquisition}
                      />

                      <Route
                        path='/reports/engagement'
                        Component={ReportEngagement}
                      />

                      <Route
                        path='/reports/retention'
                        Component={ReportRetention}
                      />
                      <Route
                        path='/reports/querybuilder/:id'
                        Component={QueryExplorer}
                      />
                      <Route
                        path='/reports/queryeditor/:id/:itemid'
                        Component={QueryExplorer}
                      />

                      <Route
                        path='/reports/newdashboard'
                        Component={NewDashboard}
                      />
                      <Route
                        path='/reports/custom/list'
                        Component={ConfigCustomDashList}
                      />
                      <Route path='/reports' Component={ReportList} />
                      {/* <Route path='/500' Component={Page500} />
            <Route Component={Page404} /> */}
                    </>
                  </BaseAdmin>

                  <ToastContainer />
                </AdminProvider>
              )}
            </ThemeProvider>
          </QueryClientProvider>
        </AuthWrapper>
      )}
    </>
  );
};

export default App;
