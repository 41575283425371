import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  useBlueprintAdmin,
  trimText,
  AdminCard,
  AdminTableHead,
  AdminTableHeadCell,
  AdminTableRow,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';

interface IScopedReportingTable {
  title: string;
  limiter: string;
  beaconFilter: any;
  beaconDimensions: any;
  granularity: any;
}

const ScopedReportingTable = ({
  title,
  limiter,
  beaconFilter,
  beaconDimensions,
  granularity,
}: IScopedReportingTable) => {
  console.log('loaded!');
  const columnTitle = limiter.split('.')[1];
  const { config, jwtToken } = useBlueprintAdmin();
  const [resultSet, setResultSet] = useState<any>(undefined);
  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  useEffect(() => {
    let isMounted = true;
    cubejsApi
      .load({
        measures: ['Activity.count'],
        timeDimensions: [
          {
            dimension: 'Activity.sentAt',
            dateRange: granularity,
            granularity: undefined,
          },
        ],
        order: {
          'Activity.sentAt': 'desc',
        },
        filters: beaconFilter,
        dimensions: [limiter, ...beaconDimensions],
      })
      .then((data) => {
        if (isMounted) {
          setResultSet(data);
        }
      })
      .catch((err) => console.error(err));
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      {resultSet && beaconFilter && beaconDimensions && granularity && (
        <Grid item xs={12}>
          <AdminCard title={title}>
            <Table size='small'>
              <AdminTableHead>
                <TableRow>
                  <AdminTableHeadCell key='header-item'>
                    value
                  </AdminTableHeadCell>
                  <AdminTableHeadCell key='header-count'>
                    count
                  </AdminTableHeadCell>
                </TableRow>
              </AdminTableHead>
              <TableBody>
                {resultSet.tablePivot().map((row, index) => (
                  // <AdminTableRow key={index}>
                  //   {resultSet.tableColumns().map((c) => {
                  //     return (
                  //       <TableCell key={c.key}>
                  //         {c.key} - {row[c.key]}
                  //       </TableCell>
                  //     );
                  //   })}
                  // </AdminTableRow>
                  <AdminTableRow key={index}>
                    <TableCell key='Activity.event'>
                      {trimText(row[`${limiter}`], 50)}
                    </TableCell>
                    <TableCell key='Activity.count'>
                      {row['Activity.count']}
                    </TableCell>
                  </AdminTableRow>
                ))}
              </TableBody>
            </Table>
          </AdminCard>
        </Grid>
      )}
      {!resultSet && <Typography>Loading...</Typography>}
    </CubeProvider>
  );
};

export default ScopedReportingTable;
