import { Link, Typography } from '@mui/material';
import { AdminCard } from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import AdminChart from './AdminChart';

interface IAdminNumberWithTrend {
  title?: string;
  numberQuery?: any;
  trendQuery?: any;
}

const AdminNumberWithTrend = ({
  title,
  numberQuery,
  trendQuery,
}: IAdminNumberWithTrend) => (
  <AdminCard title={title}>
    <AdminChart
      title={title}
      height={50}
      showCard={false}
      showViewDetail={false}
      vizState={{
        chartType: 'number',
        legend: true,
        query: numberQuery,
      }}
    />
    <Link
      href={`/reports/detail/?title=${title}&query=${JSON.stringify({
        chartType: 'number',
        legend: true,
        query: numberQuery,
      })}`}
    >
      User Breakdown
    </Link>{' '}
    <br />
    <br />
    <Typography variant='caption'>
      Trend: {trendQuery.timeDimensions[0].dateRange}
    </Typography>
    <AdminChart
      showViewDetail={false}
      showCard={false}
      vizState={{
        chartType: 'sparkline',
        legend: false,
        query: trendQuery,
      }}
    />
  </AdminCard>
);

export default AdminNumberWithTrend;
