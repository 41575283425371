import cubejs from '@cubejs-client/core';
import { AdminBenchmarkAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-benchmark';
import { AdminScopedAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-core';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Link as UiLink,
} from '@mui/material';
import { orange } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useInfiniteQuery, useQuery } from 'react-query';
import { Link, redirect, useNavigate, useParams } from 'react-router-dom';
import {
  AdminTabs,
  AdminUserInfo,
  AdminRecord,
  AdminTableHead,
  AdminTableRow,
  AdminAssetViewer,
  AdminCard,
  AdminModal,
  AdminPage,
  useBlueprintAdmin,
  AdminPaginatedListV2,
  toastInfo,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import RadialChart from './../../../components/reporting/RadialChart';
import { csvReport } from '../lib/csvReport/csvReport';
import {
  approachabilityExcelMapping,
  ownershipExcelMapping,
  performanceExcelMapping,
  searchExcelMapping,
} from '../lib/csvReport/exportToExcelData';
import getQueryData from '../lib/cubejs';
import { getRadialColor } from '../utils';
import {
  expMeasures,
  perfMeasures,
  searchMeasures,
  techMeasures,
} from './slides/slides';

const fetchData =
  (
    api: AdminBenchmarkAPI,
    clientId: string,
    type: 'experience' | 'performance' | 'technology' | 'search'
  ) =>
  () => {
    const paginatedUrl = `/admin/benchmarks/clients/${clientId}/runs/type/${type}?limit=5`;
    return api
      .get({ path: paginatedUrl })
      .then((data) => {
        if (data.data) {
          return data.data;
        }
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

const fetchCompetitors = (api: AdminScopedAPI, clientId: string) => () => {
  const paginatedUrl = `/admin/benchmarks/clients/${clientId}/competitors`;
  return api
    .get({ path: paginatedUrl })
    .then((data) => {
      if (data.data) {
        return data.data;
      }
    })
    .catch((err) => {
      console.error(err);
      return err;
    });
};

const BenchmarkClientDetail = () => {
  const navigate = useNavigate();
  const { clientId } = useParams<{ clientId: string }>();
  const { config, jwtToken, adminApi, adminAssetApi } = useBlueprintAdmin();
  const [client, setClient] = useState<any>(undefined);
  const [clientPages, setClientPages] = useState<Array<any> | undefined>(
    undefined
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);
  const [isDeleteScanModalOpen, setIsDeleteScanModalOpen] =
    useState<boolean>(false);
  const [isDeleteCompetitorModalOpen, setIsDeleteCompetitorModalOpen] =
    useState<boolean>(false);
  const [thisClient, setThisClient] = useState<any>({});
  const [reportLink, setReportLink] = useState<string | undefined>('');
  const [experienceErrors, setExperienceErrors] = useState<string>('');
  const [performanceErrors, setPerformanceErrors] = useState<string>('');
  const [technologyErrors, setTechnologyErrors] = useState<string>('');
  const [searchErrors, setSearchErrors] = useState<string>('');
  const [experienceRuns, setExperienceRuns] = useState<Array<any>>([]);
  const [performanceRuns, setPerformanceRuns] = useState<Array<any>>([]);
  const [technologyRuns, setTechnologyRuns] = useState<Array<any>>([]);
  const [searchRuns, setSearchRuns] = useState<Array<any>>([]);
  const [runId, setRunId] = useState<string>('');
  const [competitorId, setCompetitorId] = useState<string>('');

  const adminBenchmarkApi = new AdminBenchmarkAPI(config, jwtToken);

  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  const experienceQuery = useInfiniteQuery(
    `experienceRun-${clientId}`,
    fetchData(adminBenchmarkApi!, clientId!, 'experience'),
    {
      getNextPageParam: (_, pages) => {
        return pages.length + 1;
      },
    }
  );

  const performanceQuery = useInfiniteQuery(
    `performanceRun-${clientId}`,
    fetchData(adminBenchmarkApi!, clientId!, 'performance'),
    {
      getNextPageParam: (_, pages) => {
        return pages.length + 1;
      },
    }
  );

  const technologyQuery = useInfiniteQuery(
    `technologyRun-${clientId}`,
    fetchData(adminBenchmarkApi!, clientId!, 'technology'),
    {
      getNextPageParam: (_, pages) => {
        return pages.length + 1;
      },
    }
  );

  const searchQuery = useInfiniteQuery(
    `searchRuns-${clientId}`,
    fetchData(adminBenchmarkApi!, clientId!, 'search'),
    {
      getNextPageParam: (_, pages) => {
        return pages.length + 1;
      },
    }
  );

  const competitorQuery = useQuery(
    `competitors-${clientId}`,
    fetchCompetitors(adminBenchmarkApi!, clientId!)
  );

  useEffect(() => {
    if (experienceQuery.data && experienceQuery.data.pages.length > 0) {
      setExperienceRuns(experienceQuery.data.pages.flat());
    }
  }, [experienceQuery.data?.pages.length]);

  useEffect(() => {
    if (performanceQuery.data && performanceQuery.data.pages.length > 0) {
      setPerformanceRuns(performanceQuery.data.pages.flat());
    }
  }, [performanceQuery.data?.pages.length]);

  useEffect(() => {
    if (technologyQuery.data && technologyQuery.data.pages.length > 0) {
      setTechnologyRuns(technologyQuery.data.pages.flat());
    }
  }, [technologyQuery.data?.pages.length]);

  useEffect(() => {
    if (searchQuery.data && searchQuery.data.pages.length > 0) {
      setSearchRuns(searchQuery.data.pages.flat());
    }
  }, [searchQuery.data?.pages.length]);

  /**
   * check for any null values in the latest benchmark scans
   * possible errors that we check for are null values or values that are <= 0
   * @param pillar experience(approachability) | performance | technology(ownership)
   * @param data the latest benchmark run
   */
  const getErrors = (
    pillar: 'experience' | 'performance' | 'technology' | 'search',
    data: any
  ) => {
    let errorString = '';
    let experienceErrorString = '';
    for (const key in data) {
      if (data[key] === null || data[key] < 0) {
        // ignore industry being null
        if (key !== 'industry') {
          errorString += `${key}: ${data[key]}, `;
        }
      }
      if (pillar === 'technology' && data[key] === 0) {
        errorString += `${key}: ${data[key]}, `;
      }
      if (
        (key === 'fleschReadingEase' && data.fleschReadingEase === null) ||
        (key === 'accessibilityErrors' && data.accessibilityErrors === null) ||
        (key === 'accessibilityNotices' &&
          data.accessibilityNotices === null) ||
        (key === 'accessibilityWarnings' && data.accessibilityWarnings === null)
      ) {
        experienceErrorString += `${key}: ${data[key]}, `;
      }
      switch (pillar) {
        case 'experience':
          setExperienceErrors(experienceErrorString);
          break;
        case 'performance':
          setPerformanceErrors(errorString);
          break;
        case 'technology':
          setTechnologyErrors(errorString);
          break;
        case 'search':
          setSearchErrors(errorString);
          break;
        default:
          break;
      }
    }
  };

  const getClient = async () => {
    console.log(`getting client`);
    const response = await adminBenchmarkApi!.getClient(clientId!);
    console.log(adminBenchmarkApi);
    if (response.success) {
      setClient(response.data);
      getErrors('experience', response.data.latestBenchmarks.experience);
      getErrors('performance', response.data.latestBenchmarks.performance);
      getErrors('technology', response.data.latestBenchmarks.technology);

      await adminAssetApi!
        .getAssetSignedUrl(response.data.latestReport)
        .then(({ data }) => {
          setReportLink(data);
        })
        .catch((e) => console.error(e));
    }
  };

  const getPagesExperienceRun = async (pageId) => {
    const response = await adminBenchmarkApi!.getPagesExperienceRun(
      clientId!,
      pageId
    );

    const pageData = response.data.page.events;
    let latestExperienceEvent = {};
    for (const event of pageData) {
      if (event.pass === 'experience') {
        latestExperienceEvent = event.properties.assets;
        break;
      }
    }

    return latestExperienceEvent;
  };

  const getPages = async () => {
    const result = await adminBenchmarkApi!.getPages(clientId!);
    const pages = result.data;

    for (const page of pages) {
      console.log(`get page data for ${page.id}`);
      const assets = await getPagesExperienceRun(page.id);
      page.assets = assets;
    }
    setClientPages(pages);
  };

  const getCount = async () => {
    // get avgExperienceScore (approachability)
    const thisExpClientValues = await getQueryData({
      cubejsApi: cubejsApi,
      measures: expMeasures,
      filters: [
        {
          member: 'ExperienceBenchmark.clientId',
          operator: 'equals',
          values: [clientId],
        },
      ],
    });
    // get avgPerformanceScore
    const thisPerfClientValues = await getQueryData({
      cubejsApi: cubejsApi,
      measures: perfMeasures,
      filters: [
        {
          member: 'PerformanceBenchmark.clientId',
          operator: 'equals',
          values: [clientId],
        },
      ],
    });

    const thisTechClientValues = await getQueryData({
      cubejsApi: cubejsApi,
      measures: techMeasures,
      filters: [
        {
          member: 'TechnologyBenchmark.clientId',
          operator: 'equals',
          values: [clientId],
        },
      ],
    });

    const thisSearchClientValues = await getQueryData({
      cubejsApi: cubejsApi,
      measures: searchMeasures,
      filters: [
        {
          member: 'SearchBenchmark.clientId',
          operator: 'equals',
          values: [clientId],
        },
      ],
    });

    setThisClient({
      ...thisExpClientValues,
      ...thisPerfClientValues,
      ...thisTechClientValues,
      ...thisSearchClientValues,
    });
  };

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    if (clientId) {
      getClient().catch((e) => {
        console.error(e);
      });

      setInterval(() => {
        getClient().catch((e) => {
          console.error(e);
        });
      }, 10000);

      getPages().catch((e) => {
        console.error(e);
      });

      getCount().catch((e) => {
        console.error(e);
      });
    }
  }, [clientId, jwtToken]);

  const vendorTemplate = (data) => (
    <TableRow key={data.id}>
      <TableCell>{data.category}</TableCell>
      <TableCell>
        {data.vendors.map((vendor, index) => (
          <div key={index}>
            <UiLink component={Link} to={`/benchmarks/vendors/${vendor.id}`}>
              {vendor.name}
            </UiLink>
            <br />
          </div>
        ))}
      </TableCell>
    </TableRow>
  );

  const activityTemplate = (data) => (
    <TableRow key={data.id}>
      <TableCell>
        {data.entityType} {data.action} by{' '}
        <AdminUserInfo adminUserId={data.userId} />{' '}
        <Moment fromNow>{data.createdAt}</Moment>
      </TableCell>
    </TableRow>
  );

  const prospectTemplate = (data) => (
    <TableRow>
      <TableCell>
        <Moment>{data.createdAt}</Moment>
      </TableCell>
      <TableCell>{data.firstName}</TableCell>
      {/* <TableCell>{data.prospect}</TableCell>
      <TableCell>{data.prospect}</TableCell>
      <TableCell>{data.prospect.external_source}</TableCell> */}
    </TableRow>
  );

  const competitorTemplate = ({ id, client }) => (
    <AdminTableRow key={id}>
      <TableCell>{client.name}</TableCell>
      <TableCell>
        <UiLink
          component={Link}
          to={`/benchmarks/clients/${clientId}/competitors/${id}`}
        >
          View
        </UiLink>
      </TableCell>
      <TableCell>
        <IconButton
          color='primary'
          aria-label='delete competitor'
          component='span'
          onClick={() => {
            setIsDeleteCompetitorModalOpen(true);
            setCompetitorId(id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </AdminTableRow>
  );

  const scanInProgress = () => (
    <Grid container item xs={12}>
      <Grid item>
        <Typography>Last Scan: In progress</Typography>
      </Grid>
      <Grid item>
        <CircularProgress
          style={{
            marginLeft: 8,
            marginTop: 4,
            height: 18,
            width: 18,
          }}
        />
      </Grid>
    </Grid>
  );

  const clientRunsTemplate = ({ id, status, createdAt, logs }) => (
    <AdminTableRow key={id}>
      <TableCell>{status}</TableCell>
      <TableCell>{logs ? logs.details : 'N/A'}</TableCell>
      <TableCell>
        <Moment fromNow>{createdAt}</Moment>
      </TableCell>
      <TableCell>
        <UiLink
          component={Link}
          to={`/benchmarks/clients/${clientId}/runs/${id}`}
        >
          View
        </UiLink>
      </TableCell>
      <TableCell>
        <IconButton
          color='primary'
          aria-label='delete scan'
          component='span'
          onClick={() => {
            setIsDeleteScanModalOpen(true);
            setRunId(id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </AdminTableRow>
  );

  const runAll = async () => {
    const responseExp = await adminBenchmarkApi!.startScan(
      clientId!,
      'experience'
    );
    const responsePerf = await adminBenchmarkApi!.startScan(
      clientId!,
      'performance'
    );
    const responseTech = await adminBenchmarkApi!.startScan(
      clientId!,
      'technology'
    );

    // @TODO, can enable when search is stable
    //const responseSearcg = await benchmarkAdminApi.startScan(clientId, "search");

    if (responseExp.success && responsePerf.success && responseTech.success) {
      history.go(0);
    }
  };

  const exportToExcel = async (
    type: 'experience' | 'performance' | 'technology' | 'search',
    fileName: 'Approachability' | 'Performance' | 'Ownership' | 'Search'
  ) => {
    switch (type) {
      case 'experience':
        await csvReport(
          config,
          clientId,
          type,
          approachabilityExcelMapping,
          fileName
        );
        break;
      case 'performance':
        await csvReport(
          config,
          clientId,
          type,
          performanceExcelMapping,
          fileName
        );
        break;
      case 'technology':
        await csvReport(
          config,
          clientId,
          type,
          ownershipExcelMapping,
          fileName
        );
        break;
      case 'search':
        await csvReport(config, clientId, type, searchExcelMapping, fileName);
        break;
    }
  };

  const startScan = async (
    type: 'experience' | 'performance' | 'technology' | 'search'
  ) => {
    const response = await adminBenchmarkApi!.startScan(clientId!, type);

    if (response.success) {
      history.go(0);
    }
  };

  const collectPerformance = async (runId) => {
    const response = await adminBenchmarkApi!.collectPerformanceRun(
      clientId!,
      runId
    );

    if (response.success) {
      history.go(0);
    }
  };

  if (clientPages) {
    if (clientPages.length === 0) {
      toastInfo('Create a page for this client to get started');
    }
  }

  return (
    <>
      <AdminModal
        title={`Export Options`}
        body={
          <Grid item container xs={12} justifyContent='space-around'>
            <Grid item>
              <Box textAlign='center'>
                <Typography variant='subtitle1'>Performance</Typography>
                <Button
                  variant='text'
                  color='primary'
                  onClick={() =>
                    navigate(
                      `/benchmarks/clients/${clientId}/report/performance`
                    )
                  }
                >
                  Starter Deck
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box textAlign='center'>
                <Typography variant='subtitle1'>Approachability</Typography>
                <Button
                  variant='text'
                  color='primary'
                  onClick={() =>
                    navigate(
                      `/benchmarks/clients/${clientId}/report/approachability`
                    )
                  }
                >
                  Starter Deck
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box textAlign='center'>
                <Typography variant='subtitle1' align='center'>
                  Ownership
                </Typography>
                <Button
                  variant='text'
                  color='primary'
                  onClick={() =>
                    navigate(`/benchmarks/clients/${clientId}/report/ownership`)
                  }
                >
                  Starter Deck
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box textAlign='center'>
                <Typography variant='subtitle1' align='center'>
                  Search
                </Typography>
                <Button
                  variant='text'
                  color='primary'
                  onClick={() =>
                    navigate(`/benchmarks/clients/${clientId}/report/search`)
                  }
                >
                  Starter Deck
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box textAlign='center'>
                <Typography variant='subtitle1' align='center'>
                  Competitive View
                </Typography>
                <Button
                  variant='text'
                  color='primary'
                  onClick={() =>
                    navigate(
                      `/benchmarks/clients/${clientId}/report/competitor`
                    )
                  }
                >
                  Starter Deck
                </Button>
              </Box>
            </Grid>
          </Grid>
        }
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        size='sm'
        fullWidth={true}
        type='dialog'
      />
      <AdminModal
        title={`Reports`}
        body={
          <Grid container justifyContent='center'>
            <Grid item>
              {client && (
                <Button
                  variant='contained'
                  color='primary'
                  disabled={
                    !client.latestRuns.experience ||
                    !client.latestRuns.performance ||
                    !client.latestRuns.technology
                  }
                  onClick={() => {
                    adminBenchmarkApi!
                      .generateReport(clientId!)
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                  style={{ marginRight: '16px' }}
                >
                  Generate
                </Button>
              )}
            </Grid>
            <Grid item>
              {client && (
                <Button
                  variant='contained'
                  color='primary'
                  disabled={
                    !client.latestRuns.experience ||
                    !client.latestRuns.performance ||
                    !client.latestRuns.technology
                  }
                  onClick={() => {
                    if (reportLink) {
                      window.location.href = reportLink;
                    }
                  }}
                >
                  Download
                </Button>
              )}
            </Grid>
          </Grid>
        }
        isOpen={isReportModalOpen}
        closeModal={() => setIsReportModalOpen(false)}
        size='sm'
        fullWidth={true}
        type='dialog'
      />
      <AdminModal
        title={`Delete Scan?`}
        body={
          <Typography variant='body1'>
            Are you sure you would like to delete this scan?
          </Typography>
        }
        actions={
          <>
            <Button
              variant='text'
              color='primary'
              onClick={() => setIsDeleteScanModalOpen(false)}
            >
              CANCEL
            </Button>
            <Button
              variant='text'
              color='primary'
              onClick={() => {
                if (runId) {
                  adminBenchmarkApi!
                    .deleteRun(clientId!, runId)
                    .catch((err) => console.error(err));
                  performanceQuery.refetch().catch((err) => console.error(err));
                  experienceQuery.refetch().catch((err) => console.error(err));
                  technologyQuery.refetch().catch((err) => console.error(err));
                  setIsDeleteScanModalOpen(false);
                }
              }}
            >
              DELETE
            </Button>
          </>
        }
        isOpen={isDeleteScanModalOpen}
        closeModal={() => setIsDeleteScanModalOpen(false)}
        size='sm'
        fullWidth={true}
        type='dialog'
      />
      <AdminModal
        title={`Delete Competitor?`}
        body={
          <Typography variant='body1'>
            Are you sure you would like to delete this competitor?
          </Typography>
        }
        actions={
          <>
            <Button
              variant='text'
              color='primary'
              onClick={() => setIsDeleteCompetitorModalOpen(false)}
            >
              CANCEL
            </Button>
            <Button
              variant='text'
              color='primary'
              onClick={() => {
                if (competitorId) {
                  adminBenchmarkApi!
                    .deleteCompetitor(clientId!, competitorId)
                    .then(() => {
                      competitorQuery.refetch().catch((err) => {
                        console.error(err);
                      });
                    })
                    .catch((err) => console.error(err));
                  setIsDeleteCompetitorModalOpen(false);
                }
              }}
            >
              DELETE
            </Button>
          </>
        }
        isOpen={isDeleteCompetitorModalOpen}
        closeModal={() => setIsDeleteCompetitorModalOpen(false)}
        size='sm'
        fullWidth={true}
        type='dialog'
      />
      {clientId && client && clientPages && (
        <AdminPage
          animation={true}
          editAction={`/benchmarks/clients/${clientId}/edit`}
          customActionText='Report'
          customAction={() => {
            setIsReportModalOpen(true);
          }}
          secondaryCustomActionText='Export'
          secondaryCustomAction={() => {
            setIsModalOpen(true);
          }}
          title={
            <>
              <Grid container item xs={12}>
                {client.icon && (
                  <Grid item style={{ paddingRight: 10 }}>
                    <AdminAssetViewer id={client.icon} width={18} />
                  </Grid>
                )}

                <Grid item>
                  <Typography variant='h4'>{client.name}</Typography>
                </Grid>
              </Grid>
            </>
          }
          breadcrumbs={[
            { href: '/benchmarks/clients', label: 'Benchmarks' },
            { href: '/benchmarks/clients', label: 'Clients' },
            {
              href: `/benchmarks/clients/${clientId}`,
              label: client.name,
            },
          ]}
        >
          {clientPages.length > 0 && (
            <>
              <AdminTabs
                tabs={[
                  {
                    title: 'Overview',
                    template: (
                      <>
                        <AdminCard
                          title='Benchmarks'
                          customAction={
                            <Button
                              variant='outlined'
                              color='primary'
                              href='#'
                              onClick={() => runAll()}
                              className='btn btn-primary btn-sm float-right'
                            >
                              Run All Scans Now
                            </Button>
                          }
                        >
                          <Grid container item xs={12} spacing={5}>
                            <Grid item xs={3}>
                              <>
                                <Typography variant='h5'>
                                  Approachability
                                  {experienceErrors !== '' && (
                                    <Tooltip
                                      title={experienceErrors}
                                      aria-label='errors'
                                    >
                                      <ErrorOutlineOutlinedIcon
                                        style={{
                                          color: orange[400],
                                          position: 'relative',
                                          top: '4px',
                                          marginLeft: '4px',
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </Typography>
                                {client.latestRuns.experience && (
                                  <>
                                    {client.latestRuns.experience.status ===
                                      'started' && <>{scanInProgress()}</>}
                                    {client.latestRuns.experience.status ===
                                      'completed' && (
                                      <>
                                        <Typography>
                                          Last Scan:{' '}
                                          <Moment fromNow>
                                            {
                                              client.latestRuns.experience
                                                .createdAt
                                            }
                                          </Moment>
                                        </Typography>
                                      </>
                                    )}
                                  </>
                                )}
                                {!client.latestRuns.experience && (
                                  <Typography>No scans yet</Typography>
                                )}
                                {client.latestBenchmarks.experience && (
                                  <>
                                    <div style={{ width: 100, height: 100 }}>
                                      <RadialChart
                                        data={[
                                          {
                                            pct: Math.round(
                                              thisClient.avgExperienceScore ?? 0
                                            ),
                                            fill: getRadialColor(
                                              thisClient.avgExperienceScore ?? 0
                                            ),
                                          },
                                        ]}
                                      />
                                    </div>
                                  </>
                                )}
                              </>
                            </Grid>
                            <Grid item xs={3}>
                              <>
                                <Typography variant='h5'>
                                  Performance
                                  {performanceErrors !== '' && (
                                    <Tooltip
                                      title={performanceErrors}
                                      aria-label='errors'
                                    >
                                      <ErrorOutlineOutlinedIcon
                                        // color='error'
                                        style={{
                                          color: orange[400],
                                          position: 'relative',
                                          top: '4px',
                                          marginLeft: '4px',
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </Typography>
                                {client.latestRuns.performance && (
                                  <>
                                    {client.latestRuns.performance.status ===
                                      'started' && <>{scanInProgress()}</>}
                                    {client.latestRuns.performance.status ===
                                      'completed' && (
                                      <>
                                        <Typography>
                                          Last Scan:{' '}
                                          <Moment fromNow>
                                            {
                                              client.latestRuns.performance
                                                .createdAt
                                            }
                                          </Moment>
                                        </Typography>
                                      </>
                                    )}
                                  </>
                                )}
                                {!client.latestRuns.experience && (
                                  <Typography>No scans yet</Typography>
                                )}
                                {client.latestBenchmarks.performance && (
                                  <>
                                    <div style={{ width: 100, height: 100 }}>
                                      <RadialChart
                                        data={[
                                          {
                                            pct: Math.round(
                                              thisClient.avgPerformanceScore ??
                                                0
                                            ),
                                            fill: getRadialColor(
                                              thisClient.avgPerformanceScore ??
                                                0
                                            ),
                                          },
                                        ]}
                                      />
                                    </div>
                                  </>
                                )}
                              </>
                            </Grid>
                            <Grid item xs={3}>
                              <>
                                <Typography variant='h5'>
                                  Ownership
                                  {technologyErrors !== '' && (
                                    <Tooltip
                                      title={technologyErrors}
                                      aria-label='errors'
                                    >
                                      <ErrorOutlineOutlinedIcon
                                        style={{
                                          color: orange[400],
                                          position: 'relative',
                                          top: '4px',
                                          marginLeft: '4px',
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </Typography>
                                {client.latestRuns.technology && (
                                  <>
                                    {client.latestRuns.technology.status ===
                                      'started' && <>{scanInProgress()}</>}
                                    {client.latestRuns.technology.status ===
                                      'completed' && (
                                      <>
                                        <Typography>
                                          Last Scan:{' '}
                                          <Moment fromNow>
                                            {
                                              client.latestRuns.technology
                                                .createdAt
                                            }
                                          </Moment>
                                        </Typography>
                                      </>
                                    )}
                                  </>
                                )}
                                {!client.latestRuns.technology && (
                                  <Typography>No scans yet</Typography>
                                )}
                                {client.latestBenchmarks.technology && (
                                  <>
                                    <div style={{ width: 100, height: 100 }}>
                                      <RadialChart
                                        data={[
                                          {
                                            pct: Math.round(
                                              thisClient.avgOwnershipScore ?? 0
                                            ),
                                            fill: getRadialColor(
                                              thisClient.avgOwnershipScore ?? 0
                                            ),
                                          },
                                        ]}
                                      />
                                    </div>
                                  </>
                                )}
                              </>
                            </Grid>
                            <Grid item xs={3}>
                              <>
                                <Typography variant='h5'>
                                  Search
                                  {searchErrors !== '' && (
                                    <Tooltip
                                      title={searchErrors}
                                      aria-label='errors'
                                    >
                                      <ErrorOutlineOutlinedIcon
                                        style={{
                                          color: orange[400],
                                          position: 'relative',
                                          top: '4px',
                                          marginLeft: '4px',
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </Typography>
                                {client.latestRuns.search && (
                                  <>
                                    {client.latestRuns.search.status ===
                                      'started' && <>{scanInProgress()}</>}
                                    {client.latestRuns.search.status ===
                                      'completed' && (
                                      <>
                                        <Typography>
                                          Last Scan:{' '}
                                          <Moment fromNow>
                                            {client.latestRuns.search.createdAt}
                                          </Moment>
                                        </Typography>
                                      </>
                                    )}
                                  </>
                                )}
                                {!client.latestRuns.search && (
                                  <Typography>No scans yet</Typography>
                                )}
                                {client.latestBenchmarks.search && (
                                  <>
                                    <div style={{ width: 100, height: 100 }}>
                                      <RadialChart
                                        data={[
                                          {
                                            pct: Math.round(
                                              thisClient.avgSearchScore ?? 0
                                            ),
                                            fill: getRadialColor(
                                              thisClient.avgSearchScore ?? 0
                                            ),
                                          },
                                        ]}
                                      />
                                    </div>
                                  </>
                                )}
                              </>
                            </Grid>
                          </Grid>
                        </AdminCard>

                        <AdminCard
                          title='Pages'
                          newAction={`/benchmarks/clients/${clientId}/pages/new`}
                        >
                          <Grid container item xs={12} spacing={5}>
                            {clientPages.map((page, i) => (
                              <Grid key={i} item xs={6}>
                                <AdminCard title={page.name}>
                                  <div
                                    style={{
                                      width: '100%',
                                    }}
                                  >
                                    {page && page.screenshot && (
                                      <AdminAssetViewer id={page.screenshot} />
                                    )}
                                  </div>
                                  <div>
                                    <UiLink
                                      component={Link}
                                      to={`/benchmarks/clients/${clientId}/pages/${page.id}`}
                                    >
                                      View
                                    </UiLink>
                                    {' | '}
                                    <UiLink
                                      component={Link}
                                      to={`/benchmarks/clients/${clientId}/pages/${page.id}/edit`}
                                    >
                                      Edit
                                    </UiLink>{' '}
                                  </div>
                                </AdminCard>
                              </Grid>
                            ))}
                          </Grid>
                        </AdminCard>
                      </>
                    ),
                  },
                  {
                    title: 'Scans',
                    template: (
                      <Grid container item xs={12} spacing={5}>
                        <Grid item xs={6}>
                          <AdminCard
                            title='Approachability'
                            exportExcelAction={
                              approachabilityExcelMapping.length !== 0 && (
                                <Button
                                  variant='contained'
                                  color='primary'
                                  href='#'
                                  onClick={() =>
                                    exportToExcel(
                                      'experience',
                                      'Approachability'
                                    )
                                  }
                                  className='btn btn-primary btn-sm float-right'
                                >
                                  Export
                                </Button>
                              )
                            }
                            customAction={
                              <Button
                                variant='outlined'
                                style={{ marginLeft: 10 }}
                                color='primary'
                                href='#'
                                onClick={() => startScan('experience')}
                                className='btn btn-primary btn-sm float-right'
                              >
                                Run Now
                              </Button>
                            }
                          >
                            <AdminPaginatedListV2
                              headings={[
                                'status',
                                'logs',
                                'timestamp',
                                'actions',
                                '',
                              ]}
                              data={experienceRuns}
                              isLoading={experienceQuery.isLoading}
                              itemTemplate={clientRunsTemplate}
                              showCard={false}
                            />
                          </AdminCard>
                        </Grid>
                        <Grid item xs={6}>
                          <AdminCard
                            title='Performance'
                            exportExcelAction={
                              performanceExcelMapping.length !== 0 && (
                                <Button
                                  variant='contained'
                                  color='primary'
                                  href='#'
                                  onClick={() =>
                                    exportToExcel('performance', 'Performance')
                                  }
                                  className='btn btn-primary btn-sm float-right'
                                >
                                  Export
                                </Button>
                              )
                            }
                            customAction={
                              <Button
                                variant='outlined'
                                color='primary'
                                href='#'
                                style={{ marginLeft: 10 }}
                                onClick={() => startScan('performance')}
                                className='btn btn-primary btn-sm float-right'
                              >
                                Run Now
                              </Button>
                            }
                          >
                            <AdminPaginatedListV2
                              headings={[
                                'status',
                                'logs',
                                'timestamp',
                                'actions',
                                '',
                              ]}
                              data={performanceRuns}
                              isLoading={technologyQuery.isLoading}
                              itemTemplate={clientRunsTemplate}
                              showCard={false}
                            />
                          </AdminCard>
                        </Grid>
                        <Grid item xs={6}>
                          <AdminCard
                            title='Ownership'
                            exportExcelAction={
                              ownershipExcelMapping.length !== 0 && (
                                <Button
                                  variant='contained'
                                  color='primary'
                                  href='#'
                                  onClick={() =>
                                    exportToExcel('technology', 'Ownership')
                                  }
                                  className='btn btn-primary btn-sm float-right'
                                >
                                  Export
                                </Button>
                              )
                            }
                            customAction={
                              <Button
                                variant='outlined'
                                color='primary'
                                href='#'
                                style={{ marginLeft: 10 }}
                                onClick={() => startScan('technology')}
                                className='btn btn-primary btn-sm float-right'
                              >
                                Run Now
                              </Button>
                            }
                          >
                            <AdminPaginatedListV2
                              headings={[
                                'status',
                                'logs',
                                'timestamp',
                                'actions',
                                '',
                              ]}
                              data={technologyRuns}
                              isLoading={technologyQuery.isLoading}
                              itemTemplate={clientRunsTemplate}
                              showCard={false}
                            />
                          </AdminCard>
                        </Grid>
                        <Grid item xs={6}>
                          <AdminCard
                            title='Search'
                            exportExcelAction={
                              searchExcelMapping.length !== 0 && (
                                <Button
                                  variant='contained'
                                  color='primary'
                                  href='#'
                                  onClick={() =>
                                    exportToExcel('search', 'Search')
                                  }
                                  className='btn btn-primary btn-sm float-right'
                                >
                                  Export
                                </Button>
                              )
                            }
                            customAction={
                              <Button
                                variant='outlined'
                                color='primary'
                                href='#'
                                style={{ marginLeft: 10 }}
                                onClick={() => startScan('search')}
                                className='btn btn-primary btn-sm float-right'
                              >
                                Run Now
                              </Button>
                            }
                          >
                            <AdminPaginatedListV2
                              headings={[
                                'status',
                                'logs',
                                'timestamp',
                                'actions',
                                '',
                              ]}
                              data={searchRuns}
                              isLoading={searchQuery.isLoading}
                              itemTemplate={clientRunsTemplate}
                              showCard={false}
                            />
                          </AdminCard>
                        </Grid>
                      </Grid>
                    ),
                  },
                  {
                    title: 'Competitors',
                    template: (
                      <Grid container item xs={12} spacing={5}>
                        <Grid item xs={12}>
                          <AdminCard
                            title='Competitors'
                            newAction={`/benchmarks/clients/${clientId}/competitors/new`}
                          >
                            <AdminPaginatedListV2
                              headings={['competitor', 'actions', '']}
                              data={competitorQuery.data}
                              isLoading={competitorQuery.isLoading}
                              itemTemplate={client ? competitorTemplate : <></>}
                              showCard={false}
                            />
                          </AdminCard>
                        </Grid>
                      </Grid>
                    ),
                  },
                  {
                    title: 'Vendors',
                    template: (
                      <Grid container item xs={12} spacing={5}>
                        <Grid item xs={12}>
                          <AdminCard title='Vendors'>
                            <Table>
                              <TableBody>
                                <AdminRecord
                                  form={false}
                                  url={`/admin/benchmarks/clients/${clientId}/vendors`}
                                  itemTemplate={vendorTemplate}
                                />
                              </TableBody>
                            </Table>
                          </AdminCard>
                        </Grid>
                      </Grid>
                    ),
                  },
                  {
                    title: 'Activity',
                    template: (
                      <Grid container item xs={12} spacing={5}>
                        <Grid item xs={12}>
                          <AdminCard title='History'>
                            <Table>
                              <TableBody>
                                <AdminRecord
                                  form={false}
                                  url={`/admin/activity/admin/entity/${clientId}`}
                                  itemTemplate={activityTemplate}
                                />
                              </TableBody>
                            </Table>
                          </AdminCard>
                        </Grid>
                      </Grid>
                    ),
                  },
                  {
                    title: 'Prospects',
                    template: (
                      <Grid container item xs={12} spacing={5}>
                        <Grid item xs={12}>
                          <AdminCard
                            title='Prospects'
                            newAction={`/benchmarks/clients/${clientId}/prospects/new`}
                          >
                            <Table>
                              <AdminTableHead>
                                <TableRow>
                                  <TableCell>Date</TableCell>
                                  <TableCell>First Name</TableCell>
                                  <TableCell>Last Name</TableCell>
                                  <TableCell>Email</TableCell>
                                  <TableCell>Source</TableCell>
                                </TableRow>
                              </AdminTableHead>
                              <TableBody>
                                <AdminRecord
                                  form={false}
                                  // url={`/admin/prospects/entity/Client/${clientId}`}
                                  url={`/admin/benchmarks/clients/${clientId}/users`}
                                  itemTemplate={prospectTemplate}
                                />
                              </TableBody>
                            </Table>
                          </AdminCard>
                        </Grid>
                      </Grid>
                    ),
                  },
                ]}
              />
            </>
          )}
          {clientPages.length === 0 &&
            redirect(`/benchmarks/clients/${clientId}/pages/new`)}
        </AdminPage>
      )}
    </>
  );
};

export default BenchmarkClientDetail;
