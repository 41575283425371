import cubejs from '@cubejs-client/core';
import { CubeProvider, useCubeQuery } from '@cubejs-client/react';
import { Col, Row, Spin, Statistic, Table } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';
import { useBlueprintAdmin } from '@deloitte-us-consulting-dd/blueprint-ux-admin';

const numberFormatter = (item) => numeral(item).format('0,0');
// const dateFormatter = (item) => moment(item).format('MMM YY');
const colors = ['#7DB3FF', '#49457B', '#FF7C78'];

const dateFormatter = (item, granularity, resultSet) => {
  switch (granularity) {
    case 'minute' || 'hour':
      return moment(item).format('h:mm:ss a');
    case 'day': {
      if (resultSet.chartPivot().length <= 8) {
        return moment(item).format('dddd');
      }
      return moment(item).format('MM-DD');
    }
    // case 'week': {
    //   const prefixes = [1, 2, 3, 4, 5];
    //   return prefixes[0 | (moment(item).date() / 7)];
    // }
    case 'month':
      return moment(item).format('MMM YY');
    default:
      return moment(item).format('h:mm:ss a');
  }
};

const xAxisFormatter = (item, resultSet) => {
  const granularity =
    resultSet.loadResponse.pivotQuery.timeDimensions[0].granularity;
  if (moment(item).isValid()) {
    return dateFormatter(item, granularity, resultSet);
  } else {
    return item;
  }
};

const CartesianChart = ({ resultSet, children, ChartComponent, height }) => (
  <ResponsiveContainer width='100%' height={height}>
    <ChartComponent margin={{ left: -10 }} data={resultSet.chartPivot()}>
      <XAxis
        axisLine={false}
        tickLine={false}
        tickFormatter={(value) => {
          return xAxisFormatter(value, resultSet);
        }}
        dataKey='x'
        minTickGap={20}
      />
      <YAxis
        axisLine={false}
        tickLine={false}
        tickFormatter={numberFormatter}
      />
      <CartesianGrid vertical={false} />
      {children}
      <Legend />
      <Tooltip
        labelFormatter={(value) => {
          return xAxisFormatter(value, resultSet);
        }}
        formatter={numberFormatter}
      />
    </ChartComponent>
  </ResponsiveContainer>
);
const TypeToChartComponent = {
  line: ({ resultSet, height }) => (
    <CartesianChart
      resultSet={resultSet}
      height={height}
      ChartComponent={LineChart}
    >
      {resultSet.seriesNames().map((series, i) => (
        <Line
          key={series.key}
          dataKey={series.key}
          name={series.title}
          stroke={colors[i]}
        />
      ))}
    </CartesianChart>
  ),
  bar: ({ resultSet, height }) => (
    <CartesianChart
      resultSet={resultSet}
      height={height}
      ChartComponent={BarChart}
    >
      {resultSet.seriesNames().map((series, i) => (
        <Bar
          key={series.key}
          stackId='a'
          dataKey={series.key}
          name={series.title}
          fill={colors[i]}
        />
      ))}
    </CartesianChart>
  ),
  area: ({ resultSet, height }) => (
    <CartesianChart
      resultSet={resultSet}
      height={height}
      ChartComponent={AreaChart}
    >
      {resultSet.seriesNames().map((series, i) => (
        <Area
          key={series.key}
          stackId='a'
          dataKey={series.key}
          name={series.title}
          stroke={colors[i]}
          fill={colors[i]}
        />
      ))}
    </CartesianChart>
  ),
  pie: ({ resultSet, height }) => (
    <ResponsiveContainer width='100%' height={height}>
      <PieChart>
        <Pie
          isAnimationActive={false}
          data={resultSet.chartPivot()}
          nameKey='x'
          dataKey={resultSet.seriesNames()[0].key}
          fill='#8884d8'
        >
          {resultSet.chartPivot().map((e, index) => (
            <Cell key={index} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Legend />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  ),
  table: ({ resultSet }) => (
    <Table
      pagination={false}
      columns={resultSet
        .tableColumns()
        .map((c) => ({ ...c, dataIndex: c.key }))}
      dataSource={resultSet.tablePivot()}
    />
  ),
  number: ({ resultSet }) => (
    <Row
      justify='center'
      align='middle'
      style={{
        height: '100%',
      }}
    >
      <Col>
        {resultSet.seriesNames().map((s) => (
          <Statistic
            key={resultSet.totalRow()[s.key]}
            value={resultSet.totalRow()[s.key]}
          />
        ))}
      </Col>
    </Row>
  ),
};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const SpinContainer = styled.div`
  text-align: center;
  padding: 30px 50px;
  margin-top: 30px;
`;
const Spinner = () => (
  <SpinContainer>
    <Spin size='large' />
  </SpinContainer>
);

const renderChart =
  (Component) =>
  // eslint-disable-next-line react/display-name
  ({ resultSet, error, height }) =>
    (resultSet && <Component height={height} resultSet={resultSet} />) ||
    (error && error.toString()) || <Spinner />;

const ChartRenderer = ({ vizState, chartHeight }) => {
  const { config, jwtToken } = useBlueprintAdmin();
  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });
  const { query, chartType } = vizState;
  const component = TypeToMemoChartComponent[chartType];
  const renderProps = useCubeQuery(query);
  return (
    component && (
      <CubeProvider cubejsApi={cubejsApi}>
        {renderChart(component)({ height: chartHeight, ...renderProps })}
      </CubeProvider>
    )
  );
};

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  cubejsApi: PropTypes.object,
};
ChartRenderer.defaultProps = {
  vizState: {},
  chartHeight: 300,
  cubejsApi: null,
};
export default ChartRenderer;
