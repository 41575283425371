import { Chip, Link as UiLink, TableCell } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import {
  AdminAssetViewer,
  AdminPage,
  AdminPaginatedList,
  AdminTableRow,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';

const BenchmarkBrowseByIndustry = () => {
  const { industryName } = useParams<{ industryName: string }>();

  const itemTemplate = ({ icon, id, name, active, url }, index) => (
    <AdminTableRow key={index}>
      <TableCell>{icon && <AdminAssetViewer width={24} id={icon} />}</TableCell>
      <TableCell>
        <UiLink component={Link} to={`/benchmarks/clients/${id}`}>
          {name}
        </UiLink>
      </TableCell>
      <TableCell>{url}</TableCell>
      <TableCell>
        {active && <Chip color='primary' variant='filled' label='Active' />}
        {!active && (
          <Chip color='primary' variant='outlined' label='Inactive' />
        )}
      </TableCell>
      <TableCell>
        <UiLink component={Link} to={`/benchmarks/clients/${id}`}>
          View
        </UiLink>{' '}
        |{' '}
        <UiLink component={Link} to={`/benchmarks/clients/${id}/edit`}>
          Edit
        </UiLink>{' '}
      </TableCell>
    </AdminTableRow>
  );

  return (
    <>
      {industryName && (
        <AdminPage
          animation={true}
          title={`Clients in ${industryName}`}
          breadcrumbs={[
            { href: '/benchmarks/clients', label: 'All Clients' },
            {
              href: `/benchmarks/browse/industry/${industryName}`,
              label: `Clients in ${industryName}`,
            },
          ]}
        >
          <AdminPaginatedList
            columns={['icon', 'name', 'url', 'status', 'actions']}
            url={`/admin/benchmarks/industries/browse/${industryName}`}
            itemTemplate={itemTemplate}
          />
        </AdminPage>
      )}
    </>
  );
};

export default BenchmarkBrowseByIndustry;
