import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { useEffect, useState } from 'react';
import {
  AdminTabs,
  useBlueprintAdmin,
  AdminPage,
  UserProfileTemplate,
  trimText,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';
// import { DotWithLabel } from '../../utils/icons';

const getQueryParams = () =>
  window.location.search
    .replace('?', '')
    .split('&')
    .reduce(
      (r, e) => ((r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1])), r),
      {}
    );

const ReportDetail = () => {
  const { config, jwtToken } = useBlueprintAdmin();
  const [chartQuery, setChartQuery] = useState(undefined);
  const title = getQueryParams()['title'];
  const query = getQueryParams()['query'];
  const [resultSet, setResultSet] = useState<any>(undefined);
  const [tabs, setTabs] = useState<Array<any>>([]);

  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  useEffect(() => {
    if (!query) {
      return;
    }

    const newQuery = JSON.parse(query);

    console.log(newQuery);

    console.log(`setting UserID`);
    newQuery.query.dimensions = [
      ...newQuery.query.dimensions,
      'Activity.userId',
    ];

    cubejsApi
      .load(newQuery.query)
      .then((data) => {
        const results = data;
        console.log(results);

        const userArray: Array<any> = [];
        let userCount = 1;

        for (const record of data.tablePivot()) {
          const userId: string = record['Activity.userId'].toString();
          if (userId) {
            console.error(userId);
            userArray.push({
              title: trimText(userId),
              template: <UserProfileTemplate id={userId} />,
            });
          }
          userCount++;
        }

        setTabs(userArray);
      })
      .catch((err) => console.error(err));

    setChartQuery(newQuery);
  }, [query]);

  return (
    <>
      {query && chartQuery && (
        <CubeProvider cubejsApi={cubejsApi}>
          <AdminPage title='Report Detail'>
            <AdminTabs title='Users' tabs={tabs} />
          </AdminPage>
        </CubeProvider>
      )}
    </>
  );
};

export default ReportDetail;
