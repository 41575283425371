import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material';
import {
  AdminCard,
  AdminTableHead,
  AdminTableHeadCell,
  AdminTableRow,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';

function AdminBenchmarkSearchRun({ data }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <AdminCard title={`Domain`}>
          <Table>
            <AdminTableHead>
              <TableRow>
                <AdminTableHeadCell>Rank</AdminTableHeadCell>
                <AdminTableHeadCell>Organic Keywords</AdminTableHeadCell>
                <AdminTableHeadCell>Organic Traffic</AdminTableHeadCell>
                <AdminTableHeadCell>Adwords Keywords</AdminTableHeadCell>
                <AdminTableHeadCell>Adwords Traffic</AdminTableHeadCell>
                <AdminTableHeadCell>Adwords Cost</AdminTableHeadCell>
              </TableRow>
            </AdminTableHead>
            <TableBody>
              <AdminTableRow>
                <TableCell>{data.properties.overview.domain.Rank}</TableCell>
                <TableCell>
                  {data.properties.overview.domain['Organic Keywords']}
                </TableCell>
                <TableCell>
                  {data.properties.overview.domain['Organic Traffic']}
                </TableCell>
                <TableCell>
                  {data.properties.overview.domain['Adwords Keywords']}
                </TableCell>
                <TableCell>
                  {data.properties.overview.domain['Adwords Traffic']}
                </TableCell>
                <TableCell>
                  {data.properties.overview.domain['Adwords Cost']}
                </TableCell>
              </AdminTableRow>
            </TableBody>
          </Table>
        </AdminCard>
      </Grid>

      <Grid container item xs={12} spacing={3}>
        <Grid item xs={6}>
          <AdminCard title={`Backlinks`}>
            <Table>
              <AdminTableHead>
                <TableRow>
                  <AdminTableHeadCell>Field</AdminTableHeadCell>
                  <AdminTableHeadCell>Value</AdminTableHeadCell>
                </TableRow>
              </AdminTableHead>
              <TableBody>
                <AdminTableRow>
                  <TableCell>Total Backlinks</TableCell>
                  <TableCell>
                    {data.properties.backlinks.overview.total}
                  </TableCell>
                </AdminTableRow>
                <AdminTableRow>
                  <TableCell># Of Domains</TableCell>
                  <TableCell>
                    {data.properties.backlinks.overview.domains_num}
                  </TableCell>
                </AdminTableRow>
                <AdminTableRow>
                  <TableCell># Of IPs</TableCell>
                  <TableCell>
                    {data.properties.backlinks.overview.ips_num}
                  </TableCell>
                </AdminTableRow>
                <AdminTableRow>
                  <TableCell># Of Follows</TableCell>
                  <TableCell>
                    {data.properties.backlinks.overview.follows_num}
                  </TableCell>
                </AdminTableRow>
                <AdminTableRow>
                  <TableCell># Of No Follows</TableCell>
                  <TableCell>
                    {data.properties.backlinks.overview.nofollows_num}
                  </TableCell>
                </AdminTableRow>
                <AdminTableRow>
                  <TableCell># Of URLs</TableCell>
                  <TableCell>
                    {data.properties.backlinks.overview.urls_num}
                  </TableCell>
                </AdminTableRow>
                <AdminTableRow>
                  <TableCell># Of Texts</TableCell>
                  <TableCell>
                    {data.properties.backlinks.overview.texts_num}
                  </TableCell>
                </AdminTableRow>
                <AdminTableRow>
                  <TableCell># Of Forms</TableCell>
                  <TableCell>
                    {data.properties.backlinks.overview.forms_num}
                  </TableCell>
                </AdminTableRow>
                <AdminTableRow>
                  <TableCell># Of Frames</TableCell>
                  <TableCell>
                    {data.properties.backlinks.overview.frames_num}
                  </TableCell>
                </AdminTableRow>
                <AdminTableRow>
                  <TableCell># Of Images</TableCell>
                  <TableCell>
                    {data.properties.backlinks.overview.images_num}
                  </TableCell>
                </AdminTableRow>
              </TableBody>
            </Table>
          </AdminCard>
        </Grid>

        <Grid item xs={6}>
          <AdminCard
            title={`Backlinks - Competitors (${data.properties.backlinks.competitors.length})`}
          >
            <Table>
              <AdminTableHead>
                <TableRow>
                  <AdminTableHeadCell>Competitor</AdminTableHeadCell>
                  <AdminTableHeadCell>Similarity</AdminTableHeadCell>
                  <AdminTableHeadCell># Of Domains</AdminTableHeadCell>
                  <AdminTableHeadCell># Of Backlinks</AdminTableHeadCell>
                </TableRow>
              </AdminTableHead>
              <TableBody>
                {data.properties.backlinks.competitors.map((item, index) => (
                  <>
                    <AdminTableRow key={index}>
                      <TableCell>{item.neighbour}</TableCell>
                      <TableCell>{item.similarity}</TableCell>
                      <TableCell>{item.domains_num}</TableCell>
                      <TableCell>{item.backlinks_num}</TableCell>
                    </AdminTableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </AdminCard>
        </Grid>
      </Grid>

      <Grid container item xs={12} spacing={3}>
        <Grid item xs={6}>
          <AdminCard title={`Organic Search - Keywords`}>
            <Table>
              <AdminTableHead>
                <TableRow>
                  <AdminTableHeadCell>Keyword</AdminTableHeadCell>
                  <AdminTableHeadCell>Position</AdminTableHeadCell>
                  <AdminTableHeadCell>Search Volume</AdminTableHeadCell>
                  <AdminTableHeadCell>CPC</AdminTableHeadCell>
                </TableRow>
              </AdminTableHead>
              <TableBody>
                {data.properties.organic.topKeywords.map((item, index) => (
                  <>
                    <AdminTableRow key={index}>
                      <TableCell>{item['Keyword']}</TableCell>
                      <TableCell>{item['Position']}</TableCell>
                      <TableCell>{item['Search Volume']}</TableCell>
                      <TableCell>{item['CPC']}</TableCell>
                    </AdminTableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </AdminCard>
        </Grid>

        <Grid item xs={6}>
          <AdminCard
            title={`Organic Search - Competitors (${data.properties.organic.competitors.length})`}
          >
            <Table>
              <AdminTableHead>
                <TableRow>
                  <AdminTableHeadCell>Competitor</AdminTableHeadCell>
                  <AdminTableHeadCell>Relevenace</AdminTableHeadCell>
                  <AdminTableHeadCell>Common Keywords</AdminTableHeadCell>
                  <AdminTableHeadCell>Organic Keywords</AdminTableHeadCell>
                </TableRow>
              </AdminTableHead>
              <TableBody>
                {data.properties.organic.competitors.map((item, index) => (
                  <>
                    <AdminTableRow key={index}>
                      <TableCell>{item['Domain']}</TableCell>
                      <TableCell>{item['Competitor Relevance']}</TableCell>
                      <TableCell>{item['Common Keywords']}</TableCell>
                      <TableCell>{item['Organic Keywords']}</TableCell>
                    </AdminTableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </AdminCard>
        </Grid>
      </Grid>

      <Grid container item xs={12} spacing={3}>
        <Grid item xs={6}>
          <AdminCard title={`Paid Search - Keywords`}>
            <Table>
              <AdminTableHead>
                <TableRow>
                  <AdminTableHeadCell>Keyword</AdminTableHeadCell>
                  <AdminTableHeadCell>Position</AdminTableHeadCell>
                  <AdminTableHeadCell>Search Volume</AdminTableHeadCell>
                  <AdminTableHeadCell>CPC</AdminTableHeadCell>
                </TableRow>
              </AdminTableHead>
              <TableBody>
                {data.properties.paid.topKeywords.map((item, index) => (
                  <>
                    <AdminTableRow key={index}>
                      <TableCell>{item['Keyword']}</TableCell>
                      <TableCell>{item['Position']}</TableCell>
                      <TableCell>{item['Search Volume']}</TableCell>
                      <TableCell>{item['CPC']}</TableCell>
                    </AdminTableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </AdminCard>
        </Grid>

        <Grid item xs={6}>
          <AdminCard
            title={`Paid Search - Competitors (${data.properties.paid.competitors.length})`}
          >
            <Table>
              <AdminTableHead>
                <TableRow>
                  <AdminTableHeadCell>Competitor</AdminTableHeadCell>
                  <AdminTableHeadCell>Relevenace</AdminTableHeadCell>
                  <AdminTableHeadCell>Common Keywords</AdminTableHeadCell>
                  <AdminTableHeadCell>Adwords Keywords</AdminTableHeadCell>
                </TableRow>
              </AdminTableHead>
              <TableBody>
                {data.properties.paid.competitors.map((item, index) => (
                  <>
                    <AdminTableRow key={index}>
                      <TableCell>{item['Domain']}</TableCell>
                      <TableCell>{item['Competitor Relevance']}</TableCell>
                      <TableCell>{item['Common Keywords']}</TableCell>
                      <TableCell>{item['Adwords Keywords']}</TableCell>
                    </AdminTableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </AdminCard>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AdminBenchmarkSearchRun;
