import { Link as UiLink, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  AdminTableRow,
  AdminPaginatedList,
  useBlueprintAdmin,
  AdminPage,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';

const BenchmarkIndustryList = () => {
  const { config, jwtToken } = useBlueprintAdmin();

  return (
    <AdminPage
      animation={true}
      title='Industries'
      newAction='/benchmarks/industries/new'
    >
      <AdminPaginatedList
        columns={['name', 'url', 'actions']}
        url='/admin/benchmarks/industries'
        itemTemplate={itemTemplate}
        filterKeys={[]}
      />
    </AdminPage>
  );
};

const itemTemplate = ({ id, name, url }, index) => (
  <AdminTableRow key={index}>
    <TableCell>
      <UiLink component={Link} to={`/benchmarks/industries/${id}`}>
        {name}
      </UiLink>
    </TableCell>
    <TableCell>{url}</TableCell>
    <TableCell>
      <UiLink component={Link} to={`/benchmarks/industries/${id}`}>
        View
      </UiLink>
    </TableCell>
  </AdminTableRow>
);

export default BenchmarkIndustryList;
