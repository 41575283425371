import { AdminScopedAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-core';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Grid, TableCell, Link as UiLink } from '@mui/material';
import { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import {
  AdminPage,
  useBlueprintAdmin,
  AdminTableRow,
  AdminPaginatedListV2,
  AdminAssetViewer,
  AdminAssetLink,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import AdminBenchmarkIndustryList from '../../../components/AdminBenchmarkIndustryList';
import { AdminBenchmarkAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-benchmark';

const fetchData =
  (api: AdminScopedAPI, searchTerm: string) =>
  ({ pageParam = 1 }) => {
    const paginatedUrl = `/admin/benchmarks/clients/search?limit=20&page=${pageParam}`;

    return api
      .post({ path: paginatedUrl, body: { query: searchTerm } })
      .then((data) => {
        if (data.data) {
          return data.data;
        }
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

const BenchmarkClientList = () => {
  const [content, setContent] = useState<Array<any>>([]);
  const [search, setSearch] = useState<string>('');
  const { config, jwtToken } = useBlueprintAdmin();
  const adminBenchmarkApi = new AdminBenchmarkAPI(config, jwtToken);
  const navigate = useNavigate();
  const api = new AdminScopedAPI(config, 'AdminPaginatedList');

  const { data, isLoading, fetchNextPage, refetch } = useInfiniteQuery(
    'clients',
    fetchData(api, search),
    {
      getNextPageParam: (_, pages) => {
        return pages.length + 1;
      },
    }
  );

  useEffect(() => {
    /**
     * when user submits search query refetch pagination with the latest filter
     */
    refetch()
      .then((response) => {
        if (response && response.data) {
          setContent(response.data.pages.flat());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [search]);

  useEffect(() => {
    if (data && data.pages.length > 0) {
      setContent(data.pages.flat());
    }
  }, [data?.pages.length]);

  const itemTemplate = ({ icon, id, name, latestReport, url }, index) => (
    <AdminTableRow key={index}>
      <TableCell>{icon && <AdminAssetViewer width={24} id={icon} />}</TableCell>
      <TableCell>
        <UiLink component={Link} to={`/benchmarks/clients/${id}`}>
          {name}
        </UiLink>
      </TableCell>
      <TableCell>
        <Grid container alignItems='center'>
          <Grid item xs={4}>
            <UiLink>
              <FileCopyOutlinedIcon
                onClick={() => {
                  navigator.clipboard.writeText(id).catch((err) => {
                    console.log(err);
                  });
                }}
              ></FileCopyOutlinedIcon>
            </UiLink>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>{url}</TableCell>
      <TableCell>
        {latestReport && (
          <AdminAssetLink id={latestReport} caption='Download Report' />
        )}
      </TableCell>
      <TableCell>
        <UiLink component={Link} to={`/benchmarks/clients/${id}/edit`}>
          Edit
        </UiLink>{' '}
        |{' '}
        <UiLink
          component={Link}
          onClick={async () => {
            await adminBenchmarkApi!.deactivateClient(id);
            navigate(`/benchmarks/clients`);
          }}
          to='#'
        >
          Deactivate
        </UiLink>
      </TableCell>
    </AdminTableRow>
  );

  return (
    <AdminPage
      animation={true}
      title='Benchmark Clients'
      newAction='/benchmarks/clients/new'
      customActionText='Actions'
      customAction={() => {
        window.location.href = '/benchmarks/actions';
      }}
      breadcrumbs={[
        { href: '/benchmarks/clients', label: 'Benchmarks' },
        { href: '/benchmarks/clients', label: 'Clients' },
      ]}
    >
      <Grid>
        <AdminBenchmarkIndustryList />
      </Grid>
      <AdminPaginatedListV2
        headings={['icon', 'name', '', 'url', 'status', 'actions']}
        data={content}
        onInfiniteLoad={() => fetchNextPage()}
        isLoading={isLoading}
        itemTemplate={itemTemplate}
        loader={
          <tr className='loader' key={0}>
            <td>Loading ...</td>
          </tr>
        }
        search
        setSearchTerm={setSearch}
      />
    </AdminPage>
  );
};

export default BenchmarkClientList;
