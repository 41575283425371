import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminClientBaseForm from '../../../components/AdminClientBaseForm';
import { useBlueprintAdmin } from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import { AdminBenchmarkAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-benchmark';

const BenchmarkClientEdit = () => {
  const { config, jwtToken } = useBlueprintAdmin();
  const { clientId } = useParams<{ clientId: string }>();
  const navigate = useNavigate();

  const [industries, setIndustries] = useState<Array<string>>([]);
  const [sectors, setSectors] = useState<Array<string>>([]);
  const [subSectors, setSubSectors] = useState<Array<string>>([]);
  const [formData, setFormData] = useState<any>({});
  const adminBenchmarkApi = new AdminBenchmarkAPI(config, jwtToken);

  const onSuccess = (response) => {
    navigate(`/benchmarks/clients/${clientId}`);
  };

  const getClient = async () => {
    const response = await adminBenchmarkApi!.getClient(clientId!);
    const fields = response.data;

    setFormData({
      id: fields.id,
      name: fields.name,
      url: fields.url,
      industry: fields.clientIndustry ? fields.clientIndustry.id : undefined,
      sector: fields.clientSector ? fields.clientSector.id : undefined,
      subSector: fields.clientSubSector ? fields.clientSubSector.id : undefined,
    });
  };

  const getIndustries = async () => {
    const response = await fetch(
      `${config.apiRoot}/admin/benchmarks/industries`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );

    const data = await response.json();
    const list: Array<string> = [];
    for (const item of data.data) {
      list.push(item.name);
    }
    setIndustries(list);
  };

  const getSectors = async () => {
    const response = await fetch(`${config.apiRoot}/admin/benchmarks/sectors`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    const data = await response.json();
    const list: Array<string> = [];
    for (const item of data.data) {
      list.push(item.name);
    }
    setSectors(list);
  };

  const getSubSectors = async () => {
    const response = await fetch(
      `${config.apiRoot}/admin/benchmarks/sub-sectors`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );

    const data = await response.json();
    const list: Array<string> = [];
    for (const item of data.data) {
      list.push(item.name);
    }
    setSubSectors(list);
  };

  useEffect(() => {
    getClient().catch((e) => {
      console.error(e);
    });
    getIndustries().catch((e) => {
      console.error(e);
    });
    getSectors().catch((e) => {
      console.error(e);
    });
    getSubSectors().catch((e) => {
      console.error(e);
    });
  }, []);

  return (
    <>
      {clientId && (
        <>
          <AdminClientBaseForm
            id={clientId}
            url='/admin/benchmarks/clients/'
            entityTitle='Clients'
            onSuccess={onSuccess}
            breadcrumbs={[
              { href: '/benchmarks/clients', label: 'Benchmarks' },
              { href: '/benchmarks/clients', label: 'Clients' },
              {
                label: 'Edit Client',
              },
            ]}
            description={`Editing a benchmark client`}
            adminClientPage={false}
            formData={formData}
          />
        </>
      )}
    </>
  );
};

export default BenchmarkClientEdit;
