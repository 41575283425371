import { Grid } from '@mui/material';

import {
  AdminAssetViewer,
  AdminAssetLink,
  AdminCard,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import AdminBenchmarkExperienceOverview from './AdminBenchmarkExperienceOverview';
import BenchmarkNumber from './AdminBenchmarkNumber';

function AdminBenchmarkExperienceRun({ data }) {
  let accessibilityErrors, accessibilityWarnings, accessibilityNotices;

  if (data.properties.accessibility.issues) {
    if (Array.isArray(data.properties.accessibility.issues.errors)) {
      accessibilityErrors = data.properties.accessibility.issues.errors.length;
    } else {
      accessibilityErrors = data.properties.accessibility.issues.errors;
    }

    if (Array.isArray(data.properties.accessibility.issues.warnings)) {
      accessibilityWarnings =
        data.properties.accessibility.issues.warnings.length;
    } else {
      accessibilityWarnings = data.properties.accessibility.issues.warnings;
    }

    if (Array.isArray(data.properties.accessibility.issues.notices)) {
      accessibilityNotices =
        data.properties.accessibility.issues.notices.length;
    } else {
      accessibilityNotices = data.properties.accessibility.issues.notices;
    }
  }

  const experienceAccessibilityCard = (data) => (
    <>
      {data.properties.assets.accessibility.report &&
        data.properties.assets.accessibility && (
          <AdminCard
            title='Accessibility'
            customAction={
              <AdminAssetLink
                id={data.properties.assets.accessibility.report}
                caption='Download Report'
              />
            }
          >
            <Grid container item xs={12} spacing={5}>
              <Grid
                container
                item
                xs={12}
                style={{ backgroundColor: 'lightyellow' }}
              >
                <Grid container item xs={12} spacing={5}>
                  <BenchmarkNumber
                    title='Accessibility Errors'
                    value={accessibilityErrors}
                    caption='Based on WCAG2.1AA'
                    valueColor='red'
                  />
                  <BenchmarkNumber
                    title='Accessibility Warnings'
                    value={accessibilityWarnings}
                    caption='Based on WCAG2.1AA'
                    valueColor='orange'
                  />
                  <BenchmarkNumber
                    title='Accessibility Notices'
                    value={accessibilityNotices}
                    caption='Based on WCAG2.1AA'
                    valueColor='green'
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={5}>
                {experienceAccessibilityVisionImage({
                  id: data.properties.assets.vision.achromatopsia,
                  title: 'Achromatopsia',
                  caption:
                    'With achromatopsia, people see only black, white, and shades of gray',
                })}
                {experienceAccessibilityVisionImage({
                  id: data.properties.assets.vision.deuteranopia,
                  title: 'Deuteranopia',
                  caption:
                    'Form of color blindness, resulting in an inability to distinguish red from green',
                })}
                {experienceAccessibilityVisionImage({
                  id: data.properties.assets.vision.protanopia,
                  title: 'Protanopia',
                  caption:
                    'Most common form of colour blindness, causing confusion of greens, reds, and yellows',
                })}
                {experienceAccessibilityVisionImage({
                  id: data.properties.assets.vision.tritanopia,
                  title: 'Tritanopia',
                  caption:
                    'A condition where a person cannot distinguish between blue and yellow colors',
                })}
                {experienceAccessibilityVisionImage({
                  id: data.properties.assets.vision.blurredVision,
                  title: 'Blurred Vision',
                  caption: 'Simulates a blurred vision of the webpage',
                })}
              </Grid>
            </Grid>
          </AdminCard>
        )}
      {!data.properties.assets.accessibility.report ||
        (!data.properties.assets.accessibility && (
          <AdminCard title='Accessibility'>
            An accessibility scan error occured and no data could be returned.
          </AdminCard>
        ))}
    </>
  );

  const experienceAccessibilityVisionImage = ({ id, title, caption }) => (
    <Grid item xs={4}>
      <AdminAssetViewer id={id} title={title} caption={caption} />
    </Grid>
  );

  const experienceReadabilityCard = (data) => (
    <>
      {data.properties.readability && data.properties.readability.scores && (
        <AdminCard
          title='Readability'
          customAction={
            <AdminAssetLink
              id={data.properties.assets.readability.source}
              caption='Download Text'
            />
          }
        >
          <Grid container item xs={12} spacing={1} style={{ paddingTop: 10 }}>
            <Grid container item xs={12}>
              <Grid container item xs={12} spacing={1}>
                <BenchmarkNumber
                  title='Syllable Count'
                  value={data.properties.readability.scores.syllableCount}
                  caption='Describe'
                  size={4}
                />
                <BenchmarkNumber
                  title='Lexicon Count'
                  value={data.properties.readability.scores.lexiconCount}
                  caption='Describe'
                  size={4}
                />
                <BenchmarkNumber
                  title='Sentence Count'
                  value={data.properties.readability.scores.sentenceCount}
                  caption='Describe'
                  size={4}
                />
                <BenchmarkNumber
                  title='Difficult Words'
                  value={data.properties.readability.scores.difficultWords}
                  caption='Describe'
                  size={4}
                />
                <BenchmarkNumber
                  title='Flesch Kincaid'
                  value={data.properties.readability.scores.fleschKincaidGrade}
                  caption='Describe'
                  size={4}
                />
                <BenchmarkNumber
                  title='Coleman Liau'
                  value={data.properties.readability.scores.colemanLiauIndex}
                  caption='Describe'
                  size={4}
                />
                <BenchmarkNumber
                  title='Auto Readability'
                  value={
                    data.properties.readability.scores.automatedReadabilityIndex
                  }
                  caption='Describe'
                  size={4}
                />
                <BenchmarkNumber
                  title='Gunning Fog'
                  value={data.properties.readability.scores.gunningFog}
                  caption='Describe'
                  size={4}
                />
                <BenchmarkNumber
                  title='Flesch Reading Ease'
                  value={data.properties.readability.scores.fleschReadingEase}
                  caption='Describe'
                  size={4}
                />
              </Grid>
            </Grid>
          </Grid>
        </AdminCard>
      )}
      {!data.properties.readability.scores && (
        <AdminCard title='Readability'>
          A readability scan error occured and no data could be returned.
        </AdminCard>
      )}
    </>
  );

  return (
    <>
      <AdminBenchmarkExperienceOverview data={data} />
      {experienceAccessibilityCard(data)}
      {experienceReadabilityCard(data)}
    </>
  );
}

export default AdminBenchmarkExperienceRun;
