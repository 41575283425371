import { getRadialColor } from '../../pages/benchmarks/utils';
import { useLocation } from 'react-router-dom';
import RadialChart from '../../components/reporting/RadialChart';

const BenchmarksRadialChart = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const value = parseFloat(query.get('value')!);

  return (
    <div
      style={{
        width: 100,
        height: 100,
      }}
    >
      <RadialChart
        data={[
          {
            pct: value,
            fill: getRadialColor(value),
          },
        ]}
      />
    </div>
  );
};

export default BenchmarksRadialChart;
