import { Button, Grid } from '@mui/material';
import {
  AdminCard,
  AdminPage,
  useBlueprintAdmin,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import {
  exportBenchmarks,
  exportStructuralData,
  exportVendors,
} from '../lib/export';
import { AdminBenchmarkAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-benchmark';

const BenchmarkActions = () => {
  const { config, jwtToken } = useBlueprintAdmin();
  const adminBenchmarkApi = new AdminBenchmarkAPI(config, jwtToken);

  return (
    <AdminPage title='Actions'>
      <Grid container>
        <Grid item xs={12} style={{ padding: 20 }}>
          <AdminCard title='Benchmark Actions'>
            <Grid item xs={12} style={{ padding: 20 }}>
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={async () => {
                      await adminBenchmarkApi!.recalculateBenchmarkByType(
                        'experience'
                      );
                    }}
                  >
                    Recalculate Approachability
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={async () => {
                      await adminBenchmarkApi!.recalculateBenchmarkByType(
                        'performance'
                      );
                    }}
                  >
                    Recalculate Performance
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={async () => {
                      await adminBenchmarkApi!.recalculateBenchmarkByType(
                        'technology'
                      );
                    }}
                  >
                    Recalculate Ownership
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={async () => {
                      await adminBenchmarkApi!.recalculateBenchmarkByType(
                        'search'
                      );
                    }}
                  >
                    Recalculate Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: 20 }}>
              <Button
                variant='contained'
                color='primary'
                onClick={async () => {
                  await adminBenchmarkApi!.vendorUpdate();
                }}
              >
                Recalculate Vendors
              </Button>
            </Grid>
          </AdminCard>

          <AdminCard title='Export Options'>
            <Grid item xs={12} style={{ padding: 20 }}>
              <Button
                variant='contained'
                color='primary'
                onClick={async () => {
                  await exportStructuralData(config, jwtToken);
                }}
              >
                Export Client Structure
              </Button>
            </Grid>
            <Grid item xs={12} style={{ padding: 20 }}>
              <Button
                variant='contained'
                color='primary'
                onClick={async () => {
                  await exportVendors(config, jwtToken);
                }}
              >
                Export Vendors
              </Button>
            </Grid>
            <Grid item xs={12} style={{ padding: 20 }}>
              <Button
                variant='contained'
                color='primary'
                onClick={async () => {
                  await exportBenchmarks(config, jwtToken);
                }}
              >
                Export All Benchmarks
              </Button>
            </Grid>
          </AdminCard>
        </Grid>
      </Grid>
    </AdminPage>
  );
};

export default BenchmarkActions;
