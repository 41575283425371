import {
  Grid,
  Link as UiLink,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useFetch,
  AdminCard,
  AdminPage,
  useBlueprintAdmin,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';

const BenchmarkVendorDetail = () => {
  const navigate = useNavigate();
  const { vendorId } = useParams<{ vendorId: string }>();
  const { config, jwtToken } = useBlueprintAdmin();
  const [clientList, setClientList] = useState<any>([]);

  const { fetchData } = useFetch({
    url: `${config.apiRoot}/admin/benchmarks/vendors/${vendorId}/clients`,
    options: {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    },
    onSuccess: ({ data }) => {
      console.error(data);
      setClientList(data);
    },
  });

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    if (vendorId) {
      fetchData().catch((e) => {
        console.error(e);
      });
    }
  }, [vendorId, jwtToken]);

  return (
    <>
      {vendorId && (
        <AdminPage animation={true} title={`Vendor Detail`}>
          <Grid container item spacing={5} xs={12}>
            <AdminCard>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Client</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientList.map((record, index) => (
                    <TableRow key={index}>
                      <TableCell>{record.client.name}</TableCell>
                      <TableCell>
                        <UiLink
                          component={Link}
                          to={`/benchmarks/clients/${record.client.id}`}
                        >
                          View
                        </UiLink>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AdminCard>
          </Grid>
        </AdminPage>
      )}
    </>
  );
};

export default BenchmarkVendorDetail;
