import cubejs from '@cubejs-client/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Reveal from 'reveal.js';
import {
  SlideApproachabilityScore,
  SlideOne,
  SlideOverviewScore,
  SlideOwnershipScore,
  SlidePerformanceScore,
  SlideThree,
  SlideTwo,
} from '.';
import { useBlueprintAdmin } from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import getQueryData from '../../../../pages/benchmarks/lib/cubejs';
import { AdminBenchmarkAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-benchmark';

export const expMeasures = [
  'ExperienceBenchmark.minExperienceScore',
  'ExperienceBenchmark.maxExperienceScore',
  'ExperienceBenchmark.avgExperienceScore',

  'ExperienceBenchmark.minAccessibilityScore',
  'ExperienceBenchmark.maxAccessibilityScore',
  'ExperienceBenchmark.avgAccessibilityScore',

  'ExperienceBenchmark.minReadabilityScore',
  'ExperienceBenchmark.maxReadabilityScore',
  'ExperienceBenchmark.avgReadabilityScore',
];
export const perfMeasures = [
  'PerformanceBenchmark.minPerformanceScore',
  'PerformanceBenchmark.maxPerformanceScore',
  'PerformanceBenchmark.avgPerformanceScore',

  'PerformanceBenchmark.minPerformanceHappeningScore',
  'PerformanceBenchmark.maxPerformanceHappeningScore',
  'PerformanceBenchmark.avgPerformanceHappeningScore',

  'PerformanceBenchmark.minPerformanceUsefulScore',
  'PerformanceBenchmark.maxPerformanceUsefulScore',
  'PerformanceBenchmark.avgPerformanceUsefulScore',

  'PerformanceBenchmark.minPerformanceUsableScore',
  'PerformanceBenchmark.maxPerformanceUsableScore',
  'PerformanceBenchmark.avgPerformanceUsableScore',

  'PerformanceBenchmark.minPerformanceDelightfulScore',
  'PerformanceBenchmark.maxPerformanceDelightfulScore',
  'PerformanceBenchmark.avgPerformanceDelightfulScore',
];
export const techMeasures = [
  'TechnologyBenchmark.minFirstPartyDomains',
  'TechnologyBenchmark.maxFirstPartyDomains',
  'TechnologyBenchmark.avgFirstPartyDomains',

  'TechnologyBenchmark.minThirdPartyDomains',
  'TechnologyBenchmark.maxThirdPartyDomains',
  'TechnologyBenchmark.avgThirdPartyDomains',

  'TechnologyBenchmark.minThirdPartyCookies',
  'TechnologyBenchmark.maxThirdPartyCookies',
  'TechnologyBenchmark.avgThirdPartyCookies',

  'TechnologyBenchmark.minThirdPartyTrackers',
  'TechnologyBenchmark.maxThirdPartyTrackers',
  'TechnologyBenchmark.avgThirdPartyTrackers',

  'TechnologyBenchmark.minListenersMouse',
  'TechnologyBenchmark.maxListenersMouse',
  'TechnologyBenchmark.avgListenersMouse',

  'TechnologyBenchmark.minListenersKeyboard',
  'TechnologyBenchmark.maxListenersKeyboard',
  'TechnologyBenchmark.avgListenersKeyboard',

  'TechnologyBenchmark.minListenersTouch',
  'TechnologyBenchmark.maxListenersTouch',
  'TechnologyBenchmark.avgListenersTouch',

  'TechnologyBenchmark.minFingerprinters',
  'TechnologyBenchmark.maxFingerprinters',
  'TechnologyBenchmark.avgFingerprinters',

  'TechnologyBenchmark.minListenersSensor',
  'TechnologyBenchmark.maxListenersSensor',
  'TechnologyBenchmark.avgListenersSensor',

  'TechnologyBenchmark.minOwnershipScore',
  'TechnologyBenchmark.maxOwnershipScore',
  'TechnologyBenchmark.avgOwnershipScore',

  'TechnologyBenchmark.minOwnershipIdentityScore',
  'TechnologyBenchmark.maxOwnershipIdentityScore',
  'TechnologyBenchmark.avgOwnershipIdentityScore',

  'TechnologyBenchmark.minOwnershipTrustScore',
  'TechnologyBenchmark.maxOwnershipTrustScore',
  'TechnologyBenchmark.avgOwnershipTrustScore',
];
export const searchMeasures = [
  'SearchBenchmark.minSemRushRank',
  'SearchBenchmark.maxSemRushRank',
  'SearchBenchmark.avgSemRushRank',

  'SearchBenchmark.minOrganicKeywords',
  'SearchBenchmark.maxOrganicKeywords',
  'SearchBenchmark.avgOrganicKeywords',

  'SearchBenchmark.minOrganicTraffic',
  'SearchBenchmark.maxOrganicTraffic',
  'SearchBenchmark.avgOrganicTraffic',

  'SearchBenchmark.minAdwordsKeywords',
  'SearchBenchmark.maxAdwordsKeywords',
  'SearchBenchmark.avgAdwordsKeywords',

  'SearchBenchmark.minAdwordsTraffic',
  'SearchBenchmark.maxAdwordsTraffic',
  'SearchBenchmark.avgAdwordsTraffic',

  'SearchBenchmark.minAdwordsCost',
  'SearchBenchmark.maxAdwordsCost',
  'SearchBenchmark.avgAdwordsCost',

  'SearchBenchmark.minReferringDomains',
  'SearchBenchmark.maxReferringDomains',
  'SearchBenchmark.avgReferringDomains',

  'SearchBenchmark.minOrganicSearchScore',
  'SearchBenchmark.maxOrganicSearchScore',
  'SearchBenchmark.avgOrganicSearchScore',

  'SearchBenchmark.minPaidSearchScore',
  'SearchBenchmark.maxPaidSearchScore',
  'SearchBenchmark.avgPaidSearchScore',

  'SearchBenchmark.minSearchScore',
  'SearchBenchmark.maxSearchScore',
  'SearchBenchmark.avgSearchScore',
];

const BenchmarkSlides = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const { config, jwtToken } = useBlueprintAdmin();
  const adminBenchmarkApi = new AdminBenchmarkAPI(config, jwtToken);

  const [client, setClient] = useState<any>(undefined);
  const [clientPages, setClientPages] = useState<Array<any> | undefined>(
    undefined
  );
  const [thisClient, setThisClient] = useState<any>({});
  const [allClients, setAllClients] = useState<any>({});
  const [vendors, setVendors] = useState<any>([]);
  const [competitors, setCompetitors] = useState<any>({});
  const [competitorIds, setCompetitorIds] = useState<Array<any>>([]);
  const [clientIcon, setClientIcon] = useState<string>('');

  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  const getClient = async () => {
    const response = await adminBenchmarkApi!.getClient(clientId!);
    if (response.success) {
      setClient(response.data);
    }

    await getCount();
  };

  const getIcon = async () => {
    const response = await adminBenchmarkApi!.getIcon(clientId!);
    if (response.success) {
      setClientIcon(response.data);
    }
  };

  const getCompetitors = async () => {
    const response = await adminBenchmarkApi!.getCompetitors(clientId!);
    const competitorIds: string[] = [];

    if (response.success) {
      for (const comp of response.data) {
        competitorIds.push(comp.client.id);
      }
    }

    // get avgExperienceScore (approachability)
    const competitorsExpValues = await getQueryData({
      cubejsApi: cubejsApi,
      measures: expMeasures,
      filters: [
        {
          member: 'ExperienceBenchmark.clientId',
          operator: 'equals',
          values: competitorIds,
        },
      ],
    });

    // get avgPerformanceScore
    const competitorsPerfClientValues = await getQueryData({
      cubejsApi: cubejsApi,
      measures: perfMeasures,
      filters: [
        {
          member: 'PerformanceBenchmark.clientId',
          operator: 'equals',
          values: competitorIds,
        },
      ],
    });

    // get avgOwnershipScore
    const competitorsTechClientValues = await getQueryData({
      cubejsApi: cubejsApi,
      measures: techMeasures,
      filters: [
        {
          member: 'TechnologyBenchmark.clientId',
          operator: 'equals',
          values: competitorIds,
        },
      ],
    });

    setCompetitorIds(competitorIds);
    setCompetitors({
      ...competitorsExpValues,
      ...competitorsPerfClientValues,
      ...competitorsTechClientValues,
    });
  };

  const getPagesExperienceRun = async (pageId) => {
    const response = await adminBenchmarkApi!.getPagesExperienceRun(
      clientId!,
      pageId
    );

    const pageData = response.data.page.events;
    let latestExperienceEvent = {};
    for (const event of pageData) {
      if (event.pass === 'experience') {
        latestExperienceEvent = event.properties.assets;
        break;
      }
    }

    return latestExperienceEvent;
  };

  const getPages = async () => {
    const response = await adminBenchmarkApi!.getPages(clientId!);
    const pages = response.data;

    for (const page of pages) {
      console.log(`get page data for ${page.id}`);
      const assets = await getPagesExperienceRun(page.id);
      page.assets = assets;
    }
    setClientPages(pages);
  };

  const getVendors = async () => {
    const response = await adminBenchmarkApi!.getVendors(clientId!);
    if (response.success) {
      setVendors(response.data);
    }
  };

  const getCount = async () => {
    // get avgExperienceScore (approachability)
    const thisExpClientValues = await getQueryData({
      cubejsApi: cubejsApi,
      measures: expMeasures,
      filters: [
        {
          member: 'ExperienceBenchmark.clientId',
          operator: 'equals',
          values: [clientId],
        },
      ],
    });
    // get avgPerformanceScore
    const thisPerfClientValues = await getQueryData({
      cubejsApi: cubejsApi,
      measures: perfMeasures,
      filters: [
        {
          member: 'PerformanceBenchmark.clientId',
          operator: 'equals',
          values: [clientId],
        },
      ],
    });

    const thisTechClientValues = await getQueryData({
      cubejsApi: cubejsApi,
      measures: techMeasures,
      filters: [
        {
          member: 'TechnologyBenchmark.clientId',
          operator: 'equals',
          values: [clientId],
        },
      ],
    });

    setThisClient({
      ...thisExpClientValues,
      ...thisPerfClientValues,
      ...thisTechClientValues,
    });

    const allClientExpValues = await getQueryData({
      cubejsApi: cubejsApi,
      measures: expMeasures,
      filters: [
        {
          member: 'ExperienceBenchmark.industry',
          operator: 'equals',
          values: [client.clientIndustry.name],
        },
      ],
    });
    const allClientPerfValues = await getQueryData({
      cubejsApi: cubejsApi,
      measures: perfMeasures,
      filters: [
        {
          member: 'PerformanceBenchmark.industry',
          operator: 'equals',
          values: [client.clientIndustry.name],
        },
      ],
    });
    const allClientTechValues = await getQueryData({
      cubejsApi: cubejsApi,
      measures: techMeasures,
      filters: [
        {
          member: 'TechnologyBenchmark.industry',
          operator: 'equals',
          values: [client.clientIndustry.name],
        },
      ],
    });

    setAllClients({
      ...allClientExpValues,
      ...allClientPerfValues,
      ...allClientTechValues,
    });
  };

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    if (clientId) {
      getClient().catch((e) => {
        console.error(e);
      });

      getPages().catch((e) => {
        console.error(e);
      });

      getVendors().catch((e) => {
        console.error(e);
      });

      getCompetitors().catch((e) => {
        console.error(e);
      });

      getIcon().catch((e) => {
        console.error(e);
      });
    }
  }, [clientId, jwtToken]);

  useEffect(() => {
    if (client && clientPages) {
      const deck = new Reveal({
        width: 1280,
        height: 720,
        transition: 'slide',
        backgroundTransition: 'fade',
        center: false,
      });
      deck.initialize();
    }
  }, [client, clientPages]);

  try {
    require('./slides.css');
  } catch (err: any) {}

  return (
    <>
      {clientId && client && clientPages && (
        <div
          className='theme-font-montserrat theme-color-white-blue'
          style={{ width: '100%', height: '100%' }}
        >
          <div className='reveal'>
            <div className='slides'>
              <SlideOne client={client} />
              <SlideTwo />
              <SlideThree />
              <SlideOverviewScore
                clientPages={clientPages}
                client={thisClient}
                allClients={allClients}
                competitors={competitors}
              />
              <SlideApproachabilityScore
                clientInfo={client}
                client={thisClient}
                allClients={allClients}
                competitors={competitors}
                competitorIds={competitorIds}
                clientIcon={clientIcon}
              />
              <SlidePerformanceScore
                client={thisClient}
                allClients={allClients}
                competitors={competitors}
                clientIcon={clientIcon}
              />
              <SlideOwnershipScore
                client={thisClient}
                allClients={allClients}
                vendors={vendors}
                competitors={competitors}
                clientIcon={clientIcon}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BenchmarkSlides;
