import { schema, uiSchema } from '../../../forms/benchmarkClientIndustry';
import { useNavigate } from 'react-router-dom';
import { AdminBaseForm } from '@deloitte-us-consulting-dd/blueprint-ux-admin';

const BenchmarkIndustryNew = () => {
  const navigate = useNavigate();

  const onSuccess = (response) => {
    navigate(`/benchmarks/industries/${response.data.id}`);
  };

  return (
    <AdminBaseForm
      url='/admin/benchmarks/industries'
      entityTitle='Industries'
      schema={schema}
      uiSchema={uiSchema}
      onSuccess={onSuccess}
    />
  );
};

export default BenchmarkIndustryNew;
