import { AdminScopedAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-core';
import { Grid } from '@mui/material';
import AdminClientForm from './AdminClientForm';
import AdminClientPageForm from './AdminClientPageForm';

import {
  useBlueprintAdmin,
  toastError,
  AdminPage,
  AdminCard,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';

function AdminClientBaseForm({
  url,
  showSidebar = true,
  id = '',
  entityTitle,
  onSuccess,
  description,
  breadcrumbs,
  adminClientPage,
  rootValue = undefined,
  formData = {},
}): any {
  const entityUrl = id === undefined ? url : `${url}/${id}`;
  const { config } = useBlueprintAdmin();
  const api = new AdminScopedAPI(config, 'AdminBaseForm');

  const submitAction = async (formData, e) => {
    let data = formData;
    if (data.status === 'published' && !data.created_at) {
      data.created_at = new Date().getTime();
    }
    if (rootValue) {
      data = data[rootValue];
    }
    // If an ID is not present then POST, otherwise PATCH
    const responseBody = {
      path: entityUrl,
      body: formData,
    };
    const response =
      id === undefined || id === ''
        ? await api.post(responseBody)
        : await api.patch(responseBody);

    console.log(response);

    if (response.success) {
      onSuccess(response);
    } else {
      toastError('There was an error adding a new client');
    }
  };

  return (
    <AdminPage
      showSidebar={showSidebar}
      title={entityTitle ? entityTitle : undefined}
      breadcrumbs={breadcrumbs}
    >
      <Grid>
        <AdminCard>
          {adminClientPage && (
            <AdminClientPageForm
              description={description}
              handleSubmit={submitAction}
              formData={formData}
            />
          )}
          {!adminClientPage && (
            <AdminClientForm
              description={description}
              handleSubmit={submitAction}
              formData={formData}
            />
          )}
        </AdminCard>
      </Grid>
    </AdminPage>
  );
}

export default AdminClientBaseForm;
