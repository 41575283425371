import cubejs from '@cubejs-client/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Reveal from 'reveal.js';
import { useBlueprintAdmin } from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import getQueryData from '../../../../../pages/benchmarks/lib/cubejs';
import SlideFive from './slideFive';
import SlideFour from './slideFour';
import SlideOne from './slideOne';
import SlideThree from './slideThree';
import SlideTwo from './slideTwo';

const perfMeasures = [
  'PerformanceBenchmark.minPerformanceScore',
  'PerformanceBenchmark.maxPerformanceScore',
  'PerformanceBenchmark.avgPerformanceScore',

  'PerformanceBenchmark.minPerformanceHappeningScore',
  'PerformanceBenchmark.maxPerformanceHappeningScore',
  'PerformanceBenchmark.avgPerformanceHappeningScore',

  'PerformanceBenchmark.minPerformanceUsefulScore',
  'PerformanceBenchmark.maxPerformanceUsefulScore',
  'PerformanceBenchmark.avgPerformanceUsefulScore',

  'PerformanceBenchmark.minPerformanceUsableScore',
  'PerformanceBenchmark.maxPerformanceUsableScore',
  'PerformanceBenchmark.avgPerformanceUsableScore',

  'PerformanceBenchmark.minPerformanceDelightfulScore',
  'PerformanceBenchmark.maxPerformanceDelightfulScore',
  'PerformanceBenchmark.avgPerformanceDelightfulScore',
];

const BenchmarkPerformanceReport = () => {
  const { clientId } = useParams<{ clientId: string }>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { config, jwtToken, adminBenchmarkApi } = useBlueprintAdmin();
  const [client, setClient] = useState<any>(undefined);
  const [thisClient, setThisClient] = useState<any>({});
  const [industryClients, setIndustryClients] = useState<any>({});
  const [clientPages, setClientPages] = useState<Array<any> | undefined>(
    undefined
  );
  const [competitors, setCompetitors] = useState<Array<any>>([]);

  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  const getClient = async () => {
    const response = await adminBenchmarkApi!.getClient(clientId!);

    if (response.success) {
      setClient(response.data);
      getScores(response.data).catch((e) => console.error(e));
    }
  };

  const getScores = async (clientInfo: {
    clientIndustry: { name: string };
  }) => {
    // get client avgPerformance
    const clientExpValues = await getQueryData({
      cubejsApi: cubejsApi,
      measures: perfMeasures,
      filters: [
        {
          member: 'PerformanceBenchmark.clientId',
          operator: 'equals',
          values: [clientId],
        },
      ],
    });
    setThisClient(clientExpValues);

    // get industry avgPerformanceScore
    const industryExpClientValues = await getQueryData({
      cubejsApi: cubejsApi,
      measures: perfMeasures,
      filters: [
        {
          member: 'PerformanceBenchmark.industry',
          operator: 'equals',
          values: [clientInfo.clientIndustry.name],
        },
      ],
    });

    setIndustryClients(industryExpClientValues);
  };

  const getCompetitors = async () => {
    const response = await adminBenchmarkApi!.getCompetitors(clientId!);
    const tempCompetitors: any[] = [];

    if (response.success) {
      for (const comp of response.data) {
        const perfCompetitorValues = await getQueryData({
          cubejsApi: cubejsApi,
          measures: perfMeasures,
          filters: [
            {
              member: 'PerformanceBenchmark.clientId',
              operator: 'equals',
              values: [comp.client.id],
            },
          ],
        });
        comp.performance = perfCompetitorValues;
        tempCompetitors.push(comp);
      }
      setCompetitors(tempCompetitors);
    }
  };

  const getPagesExperienceRun = async (pageId) => {
    const response = await adminBenchmarkApi!.getPagesExperienceRun(
      clientId!,
      pageId
    );

    const pageData = response.data.page.events;
    let latestExperienceEvent = {};
    for (const event of pageData) {
      if (event.pass === 'experience') {
        latestExperienceEvent = event.properties.assets;
        break;
      }
    }

    return latestExperienceEvent;
  };

  const getPages = async () => {
    const response = await adminBenchmarkApi!.getPages(clientId!);
    const pages = response.data;

    for (const page of pages) {
      const assets = await getPagesExperienceRun(page.id);
      page.assets = assets;
    }
    setClientPages(pages);
  };

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    if (clientId) {
      getClient().catch((e) => {
        console.error(e);
      });
      getPages().catch((e) => {
        console.error(e);
      });
      getCompetitors().catch((e) => {
        console.error(e);
      });
    }
  }, [clientId, jwtToken]);

  useEffect(() => {
    if (client) {
      const deck = new Reveal({
        width: 1280,
        height: 720,
        transition: 'slide',
        backgroundTransition: 'fade',
        center: false,
      });
      deck.initialize();
    }
  }, [client]);

  try {
    require('./slides.css');
  } catch (err: any) {}

  return (
    <>
      {clientId && client && (
        <div
          className='theme-font-opensans theme-color-white-blue'
          style={{ width: '100%', height: '100%' }}
        >
          <div className='reveal'>
            <div className='slides'>
              <SlideOne client={client} />
              <SlideTwo
                client={thisClient}
                industryClients={industryClients}
                clientPages={clientPages}
              />
              <SlideThree
                client={thisClient}
                industryClients={industryClients}
              />
              <SlideFour client={thisClient} competitors={competitors} />
              <SlideFive />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BenchmarkPerformanceReport;
