import RadialChart from '../../../../../components/reporting/RadialChart';
import { getRadialColor } from '../../../../../pages/benchmarks/utils';

const SlideThree = ({ client, industryClients }) => {
  return (
    <section data-background-image='https://s3.amazonaws.com/media-p.slid.es/uploads/2193027/images/9741325/BG_Slide3_progressive.jpg'>
      <div
        className='sl-block'
        data-block-type='text'
        data-name='text-f1a4a7'
        style={{ height: 'auto', width: '284px', left: '80px', top: '255px' }}
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
          data-has-line-height
          dir='ui'
        >
          <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
            <span style={{ fontSize: '22px' }}>1. Perceived Speed</span>
          </h2>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          width: '549.5px',
          left: '75.5px',
          top: '144px',
          height: 'auto',
        }}
        data-name='text-46ac1f'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
          data-has-line-height
          dir='ui'
        >
          <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
            <span style={{ fontSize: '34px' }}>
              How we determined your performance score
            </span>
          </h2>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ height: 'auto', width: '290px', left: '80px', top: '296px' }}
        data-name='text-f191b6'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 12, textAlign: 'left' }}
          dir='ui'
        >
          <p style={{ fontSize: '20px' }}>Is it happening?</p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ height: 'auto', width: '290px', left: '385px', top: '255px' }}
        data-name='text-d63ef7'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
          data-has-line-height
          dir='ui'
        >
          <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
            <span style={{ fontSize: '22px' }}>2. Efficiency</span>
          </h2>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ height: 'auto', width: '290px', left: '385px', top: '296px' }}
        data-name='text-4978e1'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 14, textAlign: 'left' }}
          dir='ui'
        >
          <p style={{ fontSize: '20px' }}>Is it useful?</p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ height: 'auto', width: '290px', left: '705px', top: '296px' }}
        data-name='text-3de293'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 17, textAlign: 'left' }}
          dir='ui'
        >
          <p style={{ fontSize: '20px' }}>Is it usable?</p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          height: 'auto',
          width: '178.99px',
          left: '1025px',
          top: '255px',
        }}
        data-name='text-fd5366'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
          data-has-line-height
          dir='ui'
        >
          <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
            <span style={{ fontSize: '22px' }}>4. Experience</span>
          </h2>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          height: 'auto',
          width: '147.984px',
          left: '1025px',
          top: '296px',
        }}
        data-name='text-7b7ddb'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 21, textAlign: 'left' }}
          dir='ui'
        >
          <p style={{ fontSize: '20px' }}>Is it delightful?</p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ height: 'auto', width: '240px', left: '80px', top: '448px' }}
        data-name='text-218046'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 24, color: 'rgb(102, 102, 102)', textAlign: 'left' }}
          dir='ui'
        >
          <p style={{ fontSize: '20px' }}>
            <span style={{ fontSize: '0.7em' }}>
              Delays in how quickly initial text or images are displayed
              negatively impact user conversion.
            </span>
          </p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          height: 'auto',
          width: '250.5px',
          left: '389.5px',
          top: '448px',
        }}
        data-name='text-191046'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 25, color: 'rgb(102, 102, 102)', textAlign: 'left' }}
          dir='ui'
        >
          <p style={{ fontSize: '20px' }}>
            <span style={{ fontSize: '14px' }}>
              Longer load times for your largest asset (generally a key image /
              video) to cause abandonment.
            </span>
          </p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ height: 'auto', width: '255px', left: '705px', top: '448px' }}
        data-name='text-89bba1'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 26, color: 'rgb(102, 102, 102)', textAlign: 'left' }}
          dir='ui'
        >
          <p style={{ fontSize: '20px' }}>
            <span style={{ fontSize: '14px' }}>
              Unexpected element movements on the screen cause frustration and
              reduce return visits.
            </span>
          </p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          height: 'auto',
          width: '187.49px',
          left: '1031px',
          top: '448px',
        }}
        data-name='text-7620b2'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 27, color: 'rgb(102, 102, 102)', textAlign: 'left' }}
          dir='ui'
        >
          <p style={{ fontSize: '20px' }}>
            <span style={{ fontSize: '14px' }}>
              Rough and laggy interactions reduce user experience and
              satisfaction.
            </span>
          </p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ height: 'auto', width: '290px', left: '705px', top: '255px' }}
        data-name='text-fa7260'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
          data-has-line-height
          dir='ui'
        >
          <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
            <span style={{ fontSize: '22px' }}>3. Usability</span>
          </h2>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ height: 'auto', width: '240px', left: '80px', top: '596px' }}
        data-name='text-3c4b5d'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 29, color: 'rgb(34, 34, 34)', textAlign: 'left' }}
          dir='ui'
        >
          <p style={{ fontSize: '20px' }}>
            <span style={{ fontSize: '0.7em' }}>
              <em>
                Amazon Found Every 100ms of Latency Cost them 1% in Sales.
              </em>
            </span>
          </p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          height: 'auto',
          width: '250.5px',
          left: '389.5px',
          top: '596px',
        }}
        data-name='text-852674'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 30, color: 'rgb(34, 34, 34)', textAlign: 'left' }}
          dir='ui'
        >
          <p style={{ fontSize: '20px' }}>
            <em>
              <span style={{ fontSize: '0.7em' }}>
                40% of people abandon a website that takes more than 3 seconds
                to load.
              </span>
            </em>
          </p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ height: 'auto', width: '255px', left: '705px', top: '570px' }}
        data-name='text-1c4564'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 31, color: 'rgb(34, 34, 34)', textAlign: 'left' }}
          dir='ui'
        >
          <p style={{ fontSize: '20px' }}>
            <em>
              <span style={{ fontSize: '0.7em' }}>
                79% of customers who are “dissatisfied” with a website’s
                performance are less likely to buy from that site again.
              </span>
            </em>
          </p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          height: 'auto',
          width: '187.49px',
          left: '1031px',
          top: '544px',
        }}
        data-name='text-77b109'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 32, color: 'rgb(34, 34, 34)', textAlign: 'left' }}
          dir='ui'
        >
          <p style={{ fontSize: '20px' }}>
            <em>
              <span style={{ fontSize: '0.7em' }}>
                Walmart expects a 2% conversion increase for every 1 second of
                improvement in load time.
              </span>
            </em>
          </p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{
          height: 'auto',
          width: '729px',
          left: '275.5px',
          top: '694px',
        }}
        data-name='text-e7473b'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 33, color: 'rgb(102, 102, 102)', textAlign: 'left' }}
          dir='ui'
        >
          <p style={{ textAlign: 'center', fontSize: '20px' }}>
            <span style={{ fontSize: '0.5em' }}>
              Values are estimates and may vary. Report was conducted using lab
              and google CrUx data.{' '}
            </span>
          </p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='image'
        data-name='image-cc85e2'
        style={{
          width: '82.4071px',
          height: '38.018px',
          left: '80px',
          top: '72px',
          minWidth: '1px',
          minHeight: '1px',
        }}
      >
        <div className='sl-block-content' style={{ zIndex: 34 }}>
          <img
            style={{}}
            data-natural-width={789}
            data-natural-height={364}
            data-lazy-loaded
            data-src='https://s3.amazonaws.com/media-p.slid.es/uploads/2193027/images/9741802/DEL_PRI_Digital_RGB.png'
          />
        </div>
      </div>
      <div
        className='sl-block sl-block-group'
        data-block-type='group'
        data-name='group-e8e49d'
        style={{ height: 'auto' }}
      >
        <div
          className='sl-block-content sl-block-group-content'
          style={{ transformOrigin: '176.743px 386.837px', zIndex: 35 }}
        >
          <div
            className='sl-block'
            data-block-type='shape'
            style={{
              width: '78.5289px',
              height: '72.655px',
              left: '70px',
              top: '341.673px',
            }}
            data-name='shape-79fb8c'
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(client.avgPerformanceHappeningScore),
                  fill: getRadialColor(client.avgPerformanceHappeningScore),
                },
              ]}
            />
          </div>
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              height: 'auto',
              width: '50px',
              left: '87.2644px',
              top: '406px',
            }}
            data-name='text-ed6132'
          >
            <div
              className='sl-block-content'
              style={{ zIndex: 38, textAlign: 'left' }}
              dir='ui'
            >
              <p style={{ textAlign: 'center', fontSize: '20px' }}>
                <span style={{ color: '#808080' }}>
                  <span style={{ fontSize: '0.5em' }}>Your score</span>
                </span>
              </p>
            </div>
          </div>
          <div
            className='sl-block'
            data-block-type='shape'
            style={{
              width: '78.5289px',
              height: '72.655px',
              left: '188.721px',
              top: '341.673px',
            }}
            data-name='shape-504bad'
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(industryClients.avgPerformanceHappeningScore),
                  fill: getRadialColor(
                    industryClients.avgPerformanceHappeningScore
                  ),
                },
              ]}
            />
          </div>
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              height: 'auto',
              width: '91px',
              left: '182.485px',
              top: '406px',
            }}
            data-name='text-842d52'
          >
            <div
              className='sl-block-content'
              style={{ zIndex: 41, textAlign: 'left' }}
              dir='ui'
            >
              <p style={{ textAlign: 'center', fontSize: '20px' }}>
                <span style={{ color: '#808080' }}>
                  <span style={{ fontSize: '0.5em' }}>Your industry score</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className='sl-block sl-block-group'
        data-block-type='group'
        style={{ height: 'auto' }}
        data-name='group-cf9f00'
      >
        <div
          className='sl-block-content sl-block-group-content'
          style={{ transformOrigin: '481.743px 386.837px', zIndex: 42 }}
        >
          <div
            className='sl-block'
            data-block-type='shape'
            style={{
              width: '78.5289px',
              height: '72.655px',
              left: '375px',
              top: '341.673px',
            }}
            data-name='shape-d325f0'
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(client.avgPerformanceUsefulScore),
                  fill: getRadialColor(client.avgPerformanceUsefulScore),
                },
              ]}
            />
          </div>
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              height: 'auto',
              width: '50px',
              left: '390px',
              top: '406px',
            }}
            data-name='text-2d2274'
          >
            <div
              className='sl-block-content'
              style={{ zIndex: 45, textAlign: 'left' }}
              dir='ui'
            >
              <p style={{ textAlign: 'center', fontSize: '20px' }}>
                <span style={{ color: '#808080' }}>
                  <span style={{ fontSize: '0.5em' }}>Your score</span>
                </span>
              </p>
            </div>
          </div>
          <div
            className='sl-block'
            data-block-type='shape'
            style={{
              width: '78.5289px',
              height: '72.655px',
              left: '493.721px',
              top: '341.673px',
            }}
            data-name='shape-3a2b80'
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(industryClients.avgPerformanceUsefulScore),
                  fill: getRadialColor(
                    industryClients.avgPerformanceUsefulScore
                  ),
                },
              ]}
            />
          </div>
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              height: 'auto',
              width: '91px',
              left: '487.485px',
              top: '406px',
            }}
            data-name='text-c03a63'
          >
            <div
              className='sl-block-content'
              style={{ zIndex: 48, textAlign: 'left' }}
              dir='ui'
            >
              <p style={{ textAlign: 'center', fontSize: '20px' }}>
                <span style={{ color: '#808080' }}>
                  <span style={{ fontSize: '0.5em' }}>Your industry score</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className='sl-block sl-block-group'
        data-block-type='group'
        style={{ height: 'auto' }}
        data-name='group-f52624'
      >
        <div
          className='sl-block-content sl-block-group-content'
          style={{ transformOrigin: '801.743px 386.837px', zIndex: 49 }}
        >
          <div
            className='sl-block'
            data-block-type='shape'
            style={{
              width: '78.5289px',
              height: '72.655px',
              left: '695px',
              top: '341.673px',
            }}
            data-name='shape-5b0fd8'
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(client.avgPerformanceUsableScore),
                  fill: getRadialColor(client.avgPerformanceUsableScore),
                },
              ]}
            />
          </div>
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              height: 'auto',
              width: '50px',
              left: '712.264px',
              top: '406px',
            }}
            data-name='text-e1ac48'
          >
            <div
              className='sl-block-content'
              style={{ zIndex: 52, textAlign: 'left' }}
              dir='ui'
            >
              <p style={{ textAlign: 'center', fontSize: '20px' }}>
                <span style={{ color: '#808080' }}>
                  <span style={{ fontSize: '0.5em' }}>Your score</span>
                </span>
              </p>
            </div>
          </div>
          <div
            className='sl-block'
            data-block-type='shape'
            style={{
              width: '78.5289px',
              height: '72.655px',
              left: '813.721px',
              top: '341.673px',
            }}
            data-name='shape-1796bd'
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(industryClients.avgPerformanceUsableScore),
                  fill: getRadialColor(
                    industryClients.avgPerformanceUsableScore
                  ),
                },
              ]}
            />
          </div>
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              height: 'auto',
              width: '91px',
              left: '807.485px',
              top: '406px',
            }}
            data-name='text-0419e7'
          >
            <div
              className='sl-block-content'
              style={{ zIndex: 55, textAlign: 'left' }}
              dir='ui'
            >
              <p style={{ textAlign: 'center', fontSize: '20px' }}>
                <span style={{ color: '#808080' }}>
                  <span style={{ fontSize: '0.5em' }}>Your industry score</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className='sl-block sl-block-group'
        data-block-type='group'
        style={{ height: 'auto' }}
        data-name='group-109517'
      >
        <div
          className='sl-block-content sl-block-group-content'
          style={{ transformOrigin: '1121.74px 386.837px', zIndex: 56 }}
        >
          <div
            className='sl-block'
            data-block-type='shape'
            style={{
              width: '78.5289px',
              height: '72.655px',
              left: '1015px',
              top: '341.673px',
            }}
            data-name='shape-972bd5'
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(client.avgPerformanceDelightfulScore),
                  fill: getRadialColor(client.avgPerformanceDelightfulScore),
                },
              ]}
            />
          </div>
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              height: 'auto',
              width: '50px',
              left: '1032.26px',
              top: '406px',
            }}
            data-name='text-831da6'
          >
            <div
              className='sl-block-content'
              style={{ zIndex: 59, textAlign: 'left' }}
              dir='ui'
            >
              <p style={{ textAlign: 'center', fontSize: '20px' }}>
                <span style={{ color: '#808080' }}>
                  <span style={{ fontSize: '0.5em' }}>Your score</span>
                </span>
              </p>
            </div>
          </div>
          <div
            className='sl-block'
            data-block-type='shape'
            style={{
              width: '78.5289px',
              height: '72.655px',
              left: '1133.72px',
              top: '341.673px',
            }}
            data-name='shape-b90acf'
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(
                    industryClients.avgPerformanceDelightfulScore
                  ),
                  fill: getRadialColor(
                    industryClients.avgPerformanceDelightfulScore
                  ),
                },
              ]}
            />
          </div>
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              height: 'auto',
              width: '91px',
              left: '1127.49px',
              top: '406px',
            }}
            data-name='text-5ad078'
          >
            <div
              className='sl-block-content'
              style={{ zIndex: 62, textAlign: 'left' }}
              dir='ui'
            >
              <p style={{ textAlign: 'center', fontSize: '20px' }}>
                <span style={{ color: '#808080' }}>
                  <span style={{ fontSize: '0.5em' }}>Your industry score</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SlideThree;
