import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { IBlueprintResponse } from '@deloitte-us-consulting-dd/blueprint-sdk-core';
import { TableCell } from '@mui/material';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AdminTabs,
  AdminTableRow,
  AdminTableRowDark,
  useBlueprintAdmin,
  AdminPage,
  AdminPaginatedList,
  AdminPaginatedListV2,
  getAverage,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';

import getQueryData from '../../../../pages/benchmarks/lib/cubejs';
import { AdminBenchmarkAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-benchmark';

interface AdminTab {
  title: string;
  template: React.ReactNode;
  icon?: React.ReactNode;
}

const BenchmarkClientPageDetail = () => {
  const navigate = useNavigate();
  const { clientId, pageId } = useParams<{
    clientId: string;
    pageId: string;
  }>();
  const { config, jwtToken } = useBlueprintAdmin();
  const adminBenchmarkApi = new AdminBenchmarkAPI(config, jwtToken);

  const [page, setPage] = useState<any>(undefined);
  const [competitorsExpValues, setCompetitorsExpValues] = useState<
    | {
        avgExperienceScore: number;
        avgAccessibilityScore: number;
        avgReadabilityScore: number;
      }
    | undefined
  >();
  const [competitorsPerfValues, setCompetitorsPerfValues] = useState<any>();

  const cubejsApi = cubejs(config.reportingKey, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  const getPage = async () => {
    const response = await adminBenchmarkApi!.getPage(clientId!, pageId!);
    if (response.success) {
      setPage(response.data);
    }
  };

  const experienceQuery = useQuery(
    'PageExperience',
    () =>
      adminBenchmarkApi!
        .getPageExperience(clientId!, pageId!)
        .then((response: IBlueprintResponse) => {
          if (response.data) {
            return response.data;
          }
        })
        .catch((err) => {
          console.error(err);
          return err;
        }),
    { refetchOnWindowFocus: false }
  );

  const performanceQuery = useQuery(
    'PagePerformance',
    () =>
      adminBenchmarkApi!
        .getPagePerformance(clientId!, pageId!)
        .then((response: IBlueprintResponse) => {
          if (response.data) {
            const desktop = response.data.filter(function (el) {
              return el.deviceType === 'desktop';
            });
            const mobile = response.data.filter(function (el) {
              return el.deviceType === 'mobile';
            });
            const performanceObj = {
              desktop: desktop,
              mobile: mobile,
            };
            return performanceObj;
          }
        })
        .catch((err) => {
          console.error(err);
          return err;
        }),
    { refetchOnWindowFocus: false }
  );

  const competitorsQuery = useQuery(
    `competitors-${clientId}`,
    () =>
      adminBenchmarkApi!
        .getCompetitors(clientId!)
        .then((response) => {
          if (response.data) {
            return response.data;
          }
        })
        .catch((err) => {
          console.error(err);
          return err;
        }),
    { refetchOnWindowFocus: false }
  );

  const getCompetitorAvg = async () => {
    const competitorIds: string[] = [];

    for (const comp of competitorsQuery.data) {
      competitorIds.push(comp.client.id);
    }

    const expResponse = await getQueryData({
      cubejsApi: cubejsApi,
      measures: [
        'ExperienceBenchmark.avgExperienceScore',
        'ExperienceBenchmark.avgAccessibilityScore',
        'ExperienceBenchmark.avgReadabilityScore',
      ],
      filters: [
        {
          member: 'ExperienceBenchmark.clientId',
          operator: 'equals',
          values: competitorIds,
        },
      ],
    });
    setCompetitorsExpValues(expResponse);

    const perfResponse = await getQueryData({
      cubejsApi: cubejsApi,
      measures: [
        'PerformanceBenchmark.avgPerformanceScore',
        'PerformanceBenchmark.avgPerformanceHappeningScore',
        'PerformanceBenchmark.avgPerformanceUsefulScore',
        'PerformanceBenchmark.avgPerformanceUsableScore',
        'PerformanceBenchmark.avgPerformanceDelightfulScore',
      ],
      filters: [
        {
          member: 'PerformanceBenchmark.clientId',
          operator: 'equals',
          values: competitorIds,
        },
      ],
    });
    setCompetitorsPerfValues(perfResponse);
  };

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    if (clientId) {
      getPage().catch((e) => {
        console.error(e);
      });
    }
  }, [clientId, jwtToken]);

  useEffect(() => {
    if (competitorsQuery.isSuccess) {
      getCompetitorAvg().catch((e) => {
        console.error(e);
      });
    }
  }, [competitorsQuery.isSuccess]);

  function IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const reportTabItem = (page) => {
    return;
  };

  const formatValue = (value) => {
    if (IsJsonString(value)) {
      const parsedValue = JSON.parse(value);
      const values: Array<any> = [];

      // its an object, so parse it out
      Object.keys(parsedValue).forEach(function (key, index) {
        values.push({ key: key, value: parsedValue[key].toString() });
      });

      return {
        type: 'array',
        value: values,
      };
    } else {
      // its just a string, push it out
      return {
        type: 'string',
        value: value,
      };
    }
  };

  const renderValue = (value) => (
    <>
      {Object.keys(value).map((item, index) => (
        <p key={index}>
          <strong>{item}: </strong> {value[item]}
        </p>
      ))}
    </>
  );

  const avgTemplate = (avg: string[], content: any) => (
    <AdminTableRowDark>
      <TableCell>Average</TableCell>
      {avg?.map((item) => (
        <TableCell key={item}>{getAverage(item, content)}</TableCell>
      ))}
    </AdminTableRowDark>
  );

  const experienceTemplate = ({
    id,
    createdAt,
    experienceScore,
    accessibilityScore,
    readabilityScore,
  }) => (
    <AdminTableRow key={id}>
      <TableCell>
        <Moment fromNow>{createdAt}</Moment>
      </TableCell>
      <TableCell>{experienceScore}</TableCell>
      <TableCell>{accessibilityScore}</TableCell>
      <TableCell>{readabilityScore}</TableCell>
    </AdminTableRow>
  );

  const competitorAvgExpRow = () => (
    <AdminTableRowDark>
      <TableCell>Competitor Average</TableCell>
      <TableCell>{competitorsExpValues?.avgExperienceScore}</TableCell>
      <TableCell>{competitorsExpValues?.avgAccessibilityScore}</TableCell>
      <TableCell>{competitorsExpValues?.avgReadabilityScore}</TableCell>
    </AdminTableRowDark>
  );

  const competitorPerfRow = () => (
    <AdminTableRowDark>
      <TableCell>Competitor Average</TableCell>
      <TableCell></TableCell>
      <TableCell>{competitorsPerfValues?.avgPerformanceScore}</TableCell>
      <TableCell>
        {competitorsPerfValues?.avgPerformanceHappeningScore}
      </TableCell>
      <TableCell>{competitorsPerfValues?.avgPerformanceUsefulScore}</TableCell>
      <TableCell>{competitorsPerfValues?.avgPerformanceUsableScore}</TableCell>
      <TableCell>
        {competitorsPerfValues?.avgPerformanceDelightfulScore}
      </TableCell>
    </AdminTableRowDark>
  );

  const performanceTemplate = ({
    id,
    createdAt,
    deviceType,
    performanceScore,
    performanceHappeningScore,
    performanceUsefulScore,
    performanceUsableScore,
    performanceDelightfulScore,
  }) => (
    <AdminTableRow key={id}>
      <TableCell>
        {' '}
        <Moment fromNow>{createdAt}</Moment>
      </TableCell>
      <TableCell>{deviceType}</TableCell>
      <TableCell>{performanceScore}</TableCell>
      <TableCell>{performanceHappeningScore}</TableCell>
      <TableCell>{performanceUsefulScore}</TableCell>
      <TableCell>{performanceUsableScore}</TableCell>
      <TableCell>{performanceDelightfulScore}</TableCell>
    </AdminTableRow>
  );

  const technologyTemplate = ({ id, createdAt }) => (
    <AdminTableRow key={id}>
      <TableCell>
        {' '}
        <Moment fromNow>{createdAt}</Moment>
      </TableCell>
    </AdminTableRow>
  );

  const experienceList = () => (
    <AdminPaginatedListV2
      title='Experience Benchmarks'
      headings={[
        'Timestamp',
        'Total Experience Score',
        'Accessibility Score',
        'Readability Score',
      ]}
      data={experienceQuery.data}
      isLoading={experienceQuery.isLoading}
      itemTemplate={experienceTemplate}
      customRows={[
        avgTemplate(
          ['experienceScore', 'accessibilityScore', 'readabilityScore'],
          experienceQuery.data
        ),
        competitorAvgExpRow(),
      ]}
    />
  );

  const performanceList = () => (
    <AdminPaginatedListV2
      title='Performance Benchmarks'
      headings={[
        'Timestamp',
        'Device',
        'Total Performance Score',
        'Happening Score',
        'Useful Score',
        'Usable Score',
        'Delightful Score',
      ]}
      data={[
        ...performanceQuery.data.desktop,
        ...performanceQuery.data.mobile,
      ]?.sort((a, b) => a.valueOf() - b.valueOf())}
      isLoading={performanceQuery.isLoading}
      itemTemplate={performanceTemplate}
      customRows={[
        avgTemplate(
          [
            'desktop',
            'performanceScore',
            'performanceHappeningScore',
            'performanceUsefulScore',
            'performanceUsableScore',
            'performanceDelightfulScore',
          ],
          performanceQuery.data.desktop
        ),
        avgTemplate(
          [
            'mobile',
            'performanceScore',
            'performanceHappeningScore',
            'performanceUsefulScore',
            'performanceUsableScore',
            'performanceDelightfulScore',
          ],
          performanceQuery.data.mobile
        ),
        competitorPerfRow(),
      ]}
    />
  );

  const technologyList = () => (
    <AdminPaginatedList
      title='Ownership Benchmarks'
      columns={['Timestamp', 'Total Ownership Score']}
      url={`/admin/benchmarks/clients/${clientId}/pages/${pageId}/benchmarks/technology`}
      itemTemplate={technologyTemplate}
      filterKeys={['']}
    />
  );

  return (
    <>
      {clientId && pageId && page && (
        <CubeProvider cubejsApi={cubejsApi}>
          <AdminPage
            animation={true}
            title={page.page.name}
            editAction={`/benchmarks/clients/${clientId}/${pageId}/edit`}
            breadcrumbs={[
              { href: '/benchmarks/clients', label: 'Benchmarks' },
              { href: '/benchmarks/clients', label: 'Clients' },
              {
                href: `/benchmarks/clients/${clientId}`,
                label: clientId,
                // TODO - Client name not available in scope, need to pull through, using clientId as a fallback
              },
              {
                label: 'Details',
              },
            ]}
          >
            <AdminTabs
              tabs={[
                {
                  title: 'Approachability',
                  template: <>{experienceList()}</>,
                },
                {
                  title: 'Performance',
                  template: <>{performanceList()}</>,
                },
                {
                  title: 'Ownership',
                  template: <>{technologyList()}</>,
                },
              ]}
            />
          </AdminPage>
        </CubeProvider>
      )}
    </>
  );
};

export default BenchmarkClientPageDetail;
