import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AdminHorizontalFlow,
  AdminToastWarning,
  AdminPage,
  useBlueprintAdmin,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import NewDashDetial from './NewDashDetail';

const NewDashboard = () => {
  const { adminDashboardApi } = useBlueprintAdmin();
  const navigate = useNavigate();
  const [cancelWarningTrigger, setCancelWarningTrigger]: any = useState(null);
  const validate = (values) => {
    const errors: any = {};
    if (values.dashboardName.length === 0) {
      errors.dashboardName = 'Please enter a dashboard name';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      dashboardDescription: '',
      dashboardName: '',
    },
    onSubmit: (values) => {
      console.log('do nothing');
    },
    validate,
    validateOnChange: false,
  });

  const onCancelAny = () => {
    if (!cancelWarningTrigger) {
      setCancelWarningTrigger(true);
    } else {
      setCancelWarningTrigger(!cancelWarningTrigger);
    }
  };

  const handleSubmit = async () => {
    console.log(formik.values);
    const errors = await formik.validateForm();
    if (!(Object.keys(errors).length === 0)) {
      return false;
    }
    const newDashboard = {
      name: formik.values.dashboardName,
      description: formik.values.dashboardDescription,
    };
    const response = await adminDashboardApi!.createDashboard(newDashboard);
    if (response.success) {
      navigate(`/reports/customdashboard/${response.data.id}`);
      return true;
    } else {
      console.error('Error submitting dashboard data');
      return false;
    }
  };

  const flowConst = [
    {
      title: 'Dashboard Details',
      template: <NewDashDetial formik={formik} />,
      onSubmit: handleSubmit,
      onBackup: () => {
        return true;
      },
      onCancel: onCancelAny,
      hidden: false,
    },
  ];

  return (
    <>
      {cancelWarningTrigger && (
        <AdminToastWarning
          message={'Are you sure you want to cancel?'}
          exitLocation={null}
          trigger={cancelWarningTrigger}
        ></AdminToastWarning>
      )}
      <AdminPage showSidebar={false} title='Create New Dashboard'>
        <Grid container>
          <Grid item xs={12}>
            <AdminHorizontalFlow
              flow={flowConst}
              submitButtonLabel='CREATE DASHBOARD'
            ></AdminHorizontalFlow>
          </Grid>
        </Grid>
      </AdminPage>
    </>
  );
};

export default NewDashboard;
