import RadialChart from '../../../../../components/reporting/RadialChart';
import { getRadialColor } from '../../../../../pages/benchmarks/utils';

const SlideFour = ({ client, competitors }) => {
  return (
    <section data-background-image='https://s3.amazonaws.com/media-p.slid.es/uploads/2193027/images/9758117/BG_Slide2_progressive3.jpg'>
      <div
        className='sl-block'
        data-block-type='image'
        data-name='image-cc85e2'
        style={{
          width: '82.4071px',
          height: '38.018px',
          left: '80px',
          top: '72px',
          minWidth: '1px',
          minHeight: '1px',
        }}
      >
        <div className='sl-block-content' style={{ zIndex: 12 }}>
          <img
            style={{}}
            data-natural-width={789}
            data-natural-height={364}
            data-lazy-loaded
            data-src='https://s3.amazonaws.com/media-p.slid.es/uploads/2193027/images/9741802/DEL_PRI_Digital_RGB.png'
          />
        </div>
      </div>
      {competitors.length === 1 && (
        <>
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              width: '549.5px',
              left: '80px',
              top: '144px',
              height: 'auto',
            }}
            data-name='text-46ac1f'
          >
            <div
              className='sl-block-content'
              style={{ zIndex: 13, fontSize: '158%', lineHeight: '1.04' }}
              data-has-line-height=''
              dir='ui'
            >
              <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
                <span style={{ fontSize: '34px' }}>
                  How that compares to your direct competitors
                </span>
              </h2>
            </div>
          </div>
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              height: 'auto',
              width: '729px',
              left: '275.5px',
              top: '694px',
            }}
            data-name='text-e7473b'
          >
            <div
              className='sl-block-content'
              style={{
                zIndex: 11,
                color: 'rgb(102, 102, 102)',
                textAlign: 'left',
              }}
              dir='ui'
            >
              <p style={{ textAlign: 'center', fontSize: '20px' }}>
                <span style={{ fontSize: '0.5em' }}>
                  Values are estimates and may vary. Report was conducted using
                  lab and google CrUx data.{' '}
                </span>
              </p>
            </div>
          </div>
          <div
            className='sl-block sl-block-group'
            data-block-type='group'
            data-name='group-deb8e6'
            style={{ height: 'auto' }}
          >
            <div
              className='sl-block-content sl-block-group-content'
              style={{ transformOrigin: '629.5px 443.5px', zIndex: 13 }}
            >
              <div
                className='sl-block'
                data-block-type='line'
                data-name='line-ee73eb'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '709.5px',
                  top: '235px',
                }}
              >
                <div
                  className='sl-block-content'
                  data-line-x1={0}
                  data-line-y1={436}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 56 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={1}
                    height={436}
                    viewBox='0 0 1 436'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '868.5px',
                  top: '235px',
                }}
                data-name='line-843e5f'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={0}
                  data-line-y1={436}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 57 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={1}
                    height={436}
                    viewBox='0 0 1 436'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                data-name='text-f1a4a7'
                style={{
                  height: 'auto',
                  width: '111px',
                  left: '574px',
                  top: '216px',
                }}
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ fontSize: '30px' }}>
                    <span style={{ fontSize: '17px' }}>Your score</span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '151px',
                  left: '718.5px',
                  top: '216px',
                }}
                data-name='text-582366'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ fontSize: '30px' }}>
                    <span style={{ fontSize: '17px' }}>
                      {competitors.length > 0 && competitors[0].client.name}
                    </span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '549.5px',
                  top: '235px',
                }}
                data-name='line-7e147f'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={0}
                  data-line-y1={436}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 62 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={1}
                    height={436}
                    viewBox='0 0 1 436'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '160px',
                  left: '389.5px',
                  top: '266.827px',
                }}
                data-name='text-bf27cd'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
                    <span style={{ fontSize: '19px' }}>Perceived Speed</span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '585px',
                  top: '261px',
                }}
                data-name='shape-79fb8c'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(client.avgPerformanceHappeningScore),
                      fill: getRadialColor(client.avgPerformanceHappeningScore),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '750.236px',
                  top: '262.173px',
                }}
                data-name='shape-eda342'
              >
                {competitors.length > 0 && (
                  <RadialChart
                    data={[
                      {
                        pct: Math.round(
                          competitors[0].performance
                            .avgPerformanceHappeningScore
                        ),
                        fill: getRadialColor(
                          competitors[0].performance
                            .avgPerformanceHappeningScore
                        ),
                      },
                    ]}
                  />
                )}
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '389.5px',
                  top: '348px',
                }}
                data-name='line-caf7b5'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={480}
                  data-line-y1={0}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 70 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={480}
                    height={1}
                    viewBox='0 0 480 1'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='480.5'
                      y1='0.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='480.5'
                      y1='0.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '389.5px',
                  top: '450px',
                }}
                data-name='line-a2435f'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={480}
                  data-line-y1={3}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 71 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={480}
                    height={3}
                    viewBox='0 0 480 3'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='480.5'
                      y1='3.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='480.5'
                      y1='3.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '389.5px',
                  top: '566px',
                }}
                data-name='line-254b3c'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={479}
                  data-line-y1={0}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 72 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={479}
                    height={1}
                    viewBox='0 0 479 1'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='479.5'
                      y1='0.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='479.5'
                      y1='0.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '584.25px',
                  top: '362.5px',
                }}
                data-name='shape-d325f0'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(client.avgPerformanceUsefulScore),
                      fill: getRadialColor(client.avgPerformanceUsefulScore),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '750.237px',
                  top: '362.5px',
                }}
                data-name='shape-3c928c'
              >
                {competitors.length > 0 && (
                  <RadialChart
                    data={[
                      {
                        pct: Math.round(
                          competitors[0].performance.avgPerformanceUsefulScore
                        ),
                        fill: getRadialColor(
                          competitors[0].performance.avgPerformanceUsefulScore
                        ),
                      },
                    ]}
                  />
                )}
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '750.236px',
                  top: '476.5px',
                }}
                data-name='shape-5b0fd8'
              >
                {competitors.length > 0 && (
                  <RadialChart
                    data={[
                      {
                        pct: Math.round(
                          competitors[0].performance.avgPerformanceUsableScore
                        ),
                        fill: getRadialColor(
                          competitors[0].performance.avgPerformanceUsableScore
                        ),
                      },
                    ]}
                  />
                )}
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '584.25px',
                  top: '476.5px',
                }}
                data-name='shape-6f9ca2'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(client.avgPerformanceUsableScore),
                      fill: getRadialColor(client.avgPerformanceUsableScore),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '113.093px',
                  left: '391.907px',
                  top: '294.827px',
                }}
                data-name='text-218046'
              >
                <div
                  className='sl-block-content'
                  style={{
                    zIndex: 85,
                    color: 'rgb(102, 102, 102)',
                    textAlign: 'left',
                  }}
                  dir='ui'
                >
                  <p style={{ fontSize: '20px' }}>
                    <span style={{ fontSize: '0.7em' }}>Is it happening?</span>
                  </p>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '160px',
                  left: '389.5px',
                  top: '367.154px',
                }}
                data-name='text-703de3'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
                    <span style={{ fontSize: '19px' }}>Efficiency</span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '113.093px',
                  left: '391.907px',
                  top: '395.154px',
                }}
                data-name='text-06e1a2'
              >
                <div
                  className='sl-block-content'
                  style={{
                    zIndex: 87,
                    color: 'rgb(102, 102, 102)',
                    textAlign: 'left',
                  }}
                  dir='ui'
                >
                  <p style={{ fontSize: '20px' }}>
                    <span style={{ fontSize: '0.7em' }}>Is it useful?</span>
                  </p>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '160px',
                  left: '389.5px',
                  top: '477.827px',
                }}
                data-name='text-16154b'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
                    <span style={{ fontSize: '19px' }}>Usability</span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '113.093px',
                  left: '391.907px',
                  top: '508.827px',
                }}
                data-name='text-be179c'
              >
                <div
                  className='sl-block-content'
                  style={{
                    zIndex: 89,
                    color: 'rgb(102, 102, 102)',
                    textAlign: 'left',
                  }}
                  dir='ui'
                >
                  <p style={{ fontSize: '20px' }}>
                    <span style={{ fontSize: '0.7em' }}>Is it usable?</span>
                  </p>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '160px',
                  left: '389.5px',
                  top: '590.999px',
                }}
                data-name='text-c21b39'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
                    <span style={{ fontSize: '19px' }}>Experience</span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '113.093px',
                  left: '391.907px',
                  top: '623.999px',
                }}
                data-name='text-e1e43d'
              >
                <div
                  className='sl-block-content'
                  style={{
                    zIndex: 91,
                    color: 'rgb(102, 102, 102)',
                    textAlign: 'left',
                  }}
                  dir='ui'
                >
                  <p style={{ fontSize: '20px' }}>
                    <span style={{ fontSize: '0.7em' }}>Is it delightful?</span>
                  </p>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '750.236px',
                  top: '591.345px',
                }}
                data-name='shape-972bd5'
              >
                {competitors.length > 0 && (
                  <RadialChart
                    data={[
                      {
                        pct: Math.round(
                          competitors[0].performance
                            .avgPerformanceDelightfulScore
                        ),
                        fill: getRadialColor(
                          competitors[0].performance
                            .avgPerformanceDelightfulScore
                        ),
                      },
                    ]}
                  />
                )}
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '584.25px',
                  top: '591.345px',
                }}
                data-name='shape-bbe8b5'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(client.avgPerformanceDelightfulScore),
                      fill: getRadialColor(
                        client.avgPerformanceDelightfulScore
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {competitors.length === 2 && (
        <>
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              width: '549.5px',
              left: '80px',
              top: '144px',
              height: 'auto',
            }}
            data-name='text-46ac1f'
          >
            <div
              className='sl-block-content'
              style={{ zIndex: 13, fontSize: '158%', lineHeight: '1.04' }}
              data-has-line-height=''
              dir='ui'
            >
              <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
                <span style={{ fontSize: '34px' }}>
                  How that compares to your direct competitors
                </span>
              </h2>
            </div>
          </div>
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              height: 'auto',
              width: '729px',
              left: '275.5px',
              top: '694px',
            }}
            data-name='text-e7473b'
          >
            <div
              className='sl-block-content'
              style={{
                zIndex: 11,
                color: 'rgb(102, 102, 102)',
                textAlign: 'left',
              }}
              dir='ui'
            >
              <p style={{ textAlign: 'center', fontSize: '20px' }}>
                <span style={{ fontSize: '0.5em' }}>
                  Values are estimates and may vary. Report was conducted using
                  lab and google CrUx data.{' '}
                </span>
              </p>
            </div>
          </div>
          <div
            className='sl-block sl-block-group'
            data-block-type='group'
            data-name='group-deb8e6'
            style={{ height: 'auto' }}
          >
            <div
              className='sl-block-content sl-block-group-content'
              style={{ transformOrigin: '640.5px 443.5px', zIndex: 13 }}
            >
              <div
                className='sl-block'
                data-block-type='line'
                data-name='line-ee73eb'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '640px',
                  top: '235px',
                }}
              >
                <div
                  className='sl-block-content'
                  data-line-x1={0}
                  data-line-y1={436}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 66 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={1}
                    height={436}
                    viewBox='0 0 1 436'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '799px',
                  top: '235px',
                }}
                data-name='line-843e5f'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={0}
                  data-line-y1={436}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 67 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={1}
                    height={436}
                    viewBox='0 0 1 436'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '960px',
                  top: '235px',
                }}
                data-name='line-0e78b7'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={0}
                  data-line-y1={436}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 68 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={1}
                    height={436}
                    viewBox='0 0 1 436'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                data-name='text-f1a4a7'
                style={{
                  height: 'auto',
                  width: '111px',
                  left: '504.5px',
                  top: '216px',
                }}
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ fontSize: '30px' }}>
                    <span style={{ fontSize: '17px' }}>Your score</span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '151px',
                  left: '649px',
                  top: '216px',
                }}
                data-name='text-582366'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ fontSize: '30px' }}>
                    <span style={{ fontSize: '17px' }}>
                      {competitors.length > 0 && competitors[0].client.name}
                    </span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '151px',
                  left: '809px',
                  top: '216px',
                }}
                data-name='text-79f295'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ fontSize: '30px' }}>
                    <span style={{ fontSize: '17px' }}>
                      {competitors.length > 0 && competitors[1].client.name}
                    </span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '480px',
                  top: '235px',
                }}
                data-name='line-7e147f'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={0}
                  data-line-y1={436}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 73 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={1}
                    height={436}
                    viewBox='0 0 1 436'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '160px',
                  left: '320px',
                  top: '266.827px',
                }}
                data-name='text-bf27cd'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 75, textAlign: 'left', lineHeight: '0.91' }}
                  dir='ui'
                  data-has-line-height
                >
                  <div
                    className='sl-block-content'
                    style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                    data-has-line-height
                    dir='ui'
                  >
                    <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
                      <span style={{ fontSize: '19px' }}>Perceived Speed</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '515.5px',
                  top: '261px',
                }}
                data-name='shape-79fb8c'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(client.avgPerformanceHappeningScore),
                      fill: getRadialColor(client.avgPerformanceHappeningScore),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '680.736px',
                  top: '262.173px',
                }}
                data-name='shape-eda342'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[0].performance.avgPerformanceHappeningScore
                      ),
                      fill: getRadialColor(
                        competitors[0].performance.avgPerformanceHappeningScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '840.736px',
                  top: '262.173px',
                }}
                data-name='shape-fa83dd'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[1].performance.avgPerformanceHappeningScore
                      ),
                      fill: getRadialColor(
                        competitors[1].performance.avgPerformanceHappeningScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '320px',
                  top: '348px',
                }}
                data-name='line-caf7b5'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={640}
                  data-line-y1={0}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 84 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={640}
                    height={1}
                    viewBox='0 0 640 1'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='640.5'
                      y1='0.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='640.5'
                      y1='0.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '320px',
                  top: '450px',
                }}
                data-name='line-a2435f'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={640}
                  data-line-y1={0}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 85 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={640}
                    height={1}
                    viewBox='0 0 640 1'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='640.5'
                      y1='0.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='640.5'
                      y1='0.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '320px',
                  top: '566px',
                }}
                data-name='line-254b3c'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={640}
                  data-line-y1={0}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 86 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={640}
                    height={1}
                    viewBox='0 0 640 1'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='640.5'
                      y1='0.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='640.5'
                      y1='0.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '514.75px',
                  top: '362.5px',
                }}
                data-name='shape-d325f0'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(client.avgPerformanceUsefulScore),
                      fill: getRadialColor(client.avgPerformanceUsefulScore),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '680.737px',
                  top: '362.5px',
                }}
                data-name='shape-3c928c'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[0].performance.avgPerformanceUsefulScore
                      ),
                      fill: getRadialColor(
                        competitors[0].performance.avgPerformanceUsefulScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '840.737px',
                  top: '362.5px',
                }}
                data-name='shape-1564cb'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[1].performance.avgPerformanceUsefulScore
                      ),
                      fill: getRadialColor(
                        competitors[1].performance.avgPerformanceUsefulScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '680.736px',
                  top: '476.5px',
                }}
                data-name='shape-5b0fd8'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[0].performance.avgPerformanceUsableScore
                      ),
                      fill: getRadialColor(
                        competitors[0].performance.avgPerformanceUsableScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '840.737px',
                  top: '476.5px',
                }}
                data-name='shape-622751'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[1].performance.avgPerformanceUsableScore
                      ),
                      fill: getRadialColor(
                        competitors[1].performance.avgPerformanceUsableScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '514.75px',
                  top: '476.5px',
                }}
                data-name='shape-6f9ca2'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(client.avgPerformanceUsefulScore),
                      fill: getRadialColor(client.avgPerformanceUsefulScore),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '113.093px',
                  left: '322.407px',
                  top: '294.827px',
                }}
                data-name='text-218046'
              >
                <div
                  className='sl-block-content'
                  style={{
                    zIndex: 103,
                    color: 'rgb(102, 102, 102)',
                    textAlign: 'left',
                  }}
                  dir='ui'
                >
                  <p style={{ fontSize: '20px' }}>
                    <span style={{ fontSize: '0.7em' }}>Is it happening?</span>
                  </p>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '160px',
                  left: '320px',
                  top: '367.154px',
                }}
                data-name='text-703de3'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
                    <span style={{ fontSize: '19px' }}>Efficiency</span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '113.093px',
                  left: '322.407px',
                  top: '395.154px',
                }}
                data-name='text-06e1a2'
              >
                <div
                  className='sl-block-content'
                  style={{
                    zIndex: 105,
                    color: 'rgb(102, 102, 102)',
                    textAlign: 'left',
                  }}
                  dir='ui'
                >
                  <p style={{ fontSize: '20px' }}>
                    <span style={{ fontSize: '0.7em' }}>Is it useful?</span>
                  </p>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '160px',
                  left: '320px',
                  top: '477.827px',
                }}
                data-name='text-16154b'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
                    <span style={{ fontSize: '19px' }}>Usability</span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '113.093px',
                  left: '322.407px',
                  top: '508.827px',
                }}
                data-name='text-be179c'
              >
                <div
                  className='sl-block-content'
                  style={{
                    zIndex: 107,
                    color: 'rgb(102, 102, 102)',
                    textAlign: 'left',
                  }}
                  dir='ui'
                >
                  <p style={{ fontSize: '20px' }}>
                    <span style={{ fontSize: '0.7em' }}>Is it usable?</span>
                  </p>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '160px',
                  left: '320px',
                  top: '590.999px',
                }}
                data-name='text-c21b39'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
                    <span style={{ fontSize: '19px' }}>Experience</span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '113.093px',
                  left: '322.407px',
                  top: '623.999px',
                }}
                data-name='text-e1e43d'
              >
                <div
                  className='sl-block-content'
                  style={{
                    zIndex: 109,
                    color: 'rgb(102, 102, 102)',
                    textAlign: 'left',
                  }}
                  dir='ui'
                >
                  <p style={{ fontSize: '20px' }}>
                    <span style={{ fontSize: '0.7em' }}>Is it delightful?</span>
                  </p>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '680.736px',
                  top: '591.345px',
                }}
                data-name='shape-972bd5'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[0].performance.avgPerformanceDelightfulScore
                      ),
                      fill: getRadialColor(
                        competitors[0].performance.avgPerformanceDelightfulScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '514.75px',
                  top: '591.345px',
                }}
                data-name='shape-bbe8b5'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(client.avgPerformanceDelightfulScore),
                      fill: getRadialColor(
                        client.avgPerformanceDelightfulScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '840.736px',
                  top: '591.345px',
                }}
                data-name='shape-a36169'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[1].performance.avgPerformanceDelightfulScore
                      ),
                      fill: getRadialColor(
                        competitors[1].performance.avgPerformanceDelightfulScore
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {competitors.length === 3 && (
        <section data-background-image='https://s3.amazonaws.com/media-p.slid.es/uploads/2193027/images/9744735/BG_Slide2_progressive2.jpg'>
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              width: '549.5px',
              left: '80px',
              top: '144px',
              height: 'auto',
            }}
            data-name='text-46ac1f'
          >
            <div
              className='sl-block-content'
              style={{ zIndex: 13, fontSize: '158%', lineHeight: '1.04' }}
              data-has-line-height=''
              dir='ui'
            >
              <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
                <span style={{ fontSize: '34px' }}>
                  How that compares to your direct competitors
                </span>
              </h2>
            </div>
          </div>
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              height: 'auto',
              width: '729px',
              left: '275.5px',
              top: '694px',
            }}
            data-name='text-e7473b'
          >
            <div
              className='sl-block-content'
              style={{
                zIndex: 11,
                color: 'rgb(102, 102, 102)',
                textAlign: 'left',
              }}
              dir='ui'
            >
              <p style={{ textAlign: 'center', fontSize: '20px' }}>
                <span style={{ fontSize: '0.5em' }}>
                  Values are estimates and may vary. Report was conducted using
                  lab and google CrUx data.{' '}
                </span>
              </p>
            </div>
          </div>
          <div
            className='sl-block sl-block-group'
            data-block-type='group'
            data-name='group-deb8e6'
            style={{ height: 'auto' }}
          >
            <div
              className='sl-block-content sl-block-group-content'
              style={{ transformOrigin: '629.5px 443.5px', zIndex: 13 }}
            >
              <div
                className='sl-block'
                data-block-type='line'
                data-name='line-ee73eb'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '549px',
                  top: '235px',
                }}
              >
                <div
                  className='sl-block-content'
                  data-line-x1={0}
                  data-line-y1={436}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 14 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={1}
                    height={436}
                    viewBox='0 0 1 436'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '708px',
                  top: '235px',
                }}
                data-name='line-843e5f'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={0}
                  data-line-y1={436}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 15 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={1}
                    height={436}
                    viewBox='0 0 1 436'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '869px',
                  top: '235px',
                }}
                data-name='line-0e78b7'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={0}
                  data-line-y1={436}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 16 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={1}
                    height={436}
                    viewBox='0 0 1 436'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                data-name='text-f1a4a7'
                style={{
                  height: 'auto',
                  width: '111px',
                  left: '413.5px',
                  top: '216px',
                }}
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ fontSize: '30px' }}>
                    <span style={{ fontSize: '17px' }}>Your score</span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '151px',
                  left: '598px',
                  top: '216px',
                }}
                data-name='text-582366'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 18, textAlign: 'left' }}
                  dir='ui'
                >
                  <div
                    className='sl-block-content'
                    style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                    data-has-line-height
                    dir='ui'
                  >
                    <h2 style={{ fontSize: '30px' }}>
                      <span style={{ fontSize: '17px' }}>
                        {competitors.length > 0 && competitors[0].client.name}
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '151px',
                  left: '715px',
                  top: '216px',
                }}
                data-name='text-79f295'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ fontSize: '30px' }}>
                    <span style={{ fontSize: '17px' }}>
                      {competitors.length > 0 && competitors[1].client.name}
                    </span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '151px',
                  left: '878px',
                  top: '216px',
                }}
                data-name='text-fa0ce6'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ fontSize: '30px' }}>
                    <span style={{ fontSize: '17px' }}>
                      {competitors.length > 0 && competitors[2].client.name}
                    </span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '389px',
                  top: '235px',
                }}
                data-name='line-7e147f'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={0}
                  data-line-y1={436}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 21 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={1}
                    height={436}
                    viewBox='0 0 1 436'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '1029px',
                  top: '235px',
                }}
                data-name='line-bb8b74'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={0}
                  data-line-y1={436}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 22 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={1}
                    height={436}
                    viewBox='0 0 1 436'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='0.5'
                      y1='436.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '160px',
                  left: '229px',
                  top: '266.827px',
                }}
                data-name='text-bf27cd'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
                    <span style={{ fontSize: '19px' }}>Perceived Speed</span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '424.5px',
                  top: '261px',
                }}
                data-name='shape-79fb8c'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(client.avgPerformanceHappeningScore),
                      fill: getRadialColor(client.avgPerformanceHappeningScore),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '589.736px',
                  top: '262.173px',
                }}
                data-name='shape-eda342'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[0].performance.avgPerformanceHappeningScore
                      ),
                      fill: getRadialColor(
                        competitors[0].performance.avgPerformanceHappeningScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '749.736px',
                  top: '262.173px',
                }}
                data-name='shape-fa83dd'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[1].performance.avgPerformanceHappeningScore
                      ),
                      fill: getRadialColor(
                        competitors[1].performance.avgPerformanceHappeningScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '909.736px',
                  top: '262.173px',
                }}
                data-name='shape-55085f'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[2].performance.avgPerformanceHappeningScore
                      ),
                      fill: getRadialColor(
                        competitors[2].performance.avgPerformanceHappeningScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '229px',
                  top: '348px',
                }}
                data-name='line-caf7b5'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={800}
                  data-line-y1={2}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 32 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={800}
                    height={2}
                    viewBox='0 0 800 2'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='800.5'
                      y1='2.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='800.5'
                      y1='2.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '229px',
                  top: '450px',
                }}
                data-name='line-a2435f'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={800}
                  data-line-y1={2}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 33 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={800}
                    height={2}
                    viewBox='0 0 800 2'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='800.5'
                      y1='2.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='800.5'
                      y1='2.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='line'
                style={{
                  width: 'auto',
                  height: 'auto',
                  minWidth: '0px',
                  minHeight: '0px',
                  left: '229px',
                  top: '566px',
                }}
                data-name='line-254b3c'
              >
                <div
                  className='sl-block-content'
                  data-line-x1={800}
                  data-line-y1={0}
                  data-line-x2={0}
                  data-line-y2={0}
                  data-line-color='#666666'
                  data-line-start-type='none'
                  data-line-end-type='none'
                  style={{ zIndex: 34 }}
                  data-line-width='1px'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    preserveAspectRatio='xMidYMid'
                    width={800}
                    height={1}
                    viewBox='0 0 800 1'
                  >
                    <line
                      stroke='rgba(0,0,0,0)'
                      strokeWidth={15}
                      x1='800.5'
                      y1='0.5'
                      x2='0.5'
                      y2='0.5'
                    />
                    <line
                      className='line-element'
                      stroke='#666666'
                      strokeWidth={1}
                      x1='800.5'
                      y1='0.5'
                      x2='0.5'
                      y2='0.5'
                    />
                  </svg>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '423.75px',
                  top: '362.5px',
                }}
                data-name='shape-d325f0'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(client.avgPerformanceUsefulScore),
                      fill: getRadialColor(client.avgPerformanceUsefulScore),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '589.737px',
                  top: '362.5px',
                }}
                data-name='shape-3c928c'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[0].performance.avgPerformanceUsefulScore
                      ),
                      fill: getRadialColor(
                        competitors[0].performance.avgPerformanceUsefulScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '749.737px',
                  top: '362.5px',
                }}
                data-name='shape-1564cb'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[1].performance.avgPerformanceUsefulScore
                      ),
                      fill: getRadialColor(
                        competitors[1].performance.avgPerformanceUsefulScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '909.736px',
                  top: '362.5px',
                }}
                data-name='shape-8c0c89'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[2].performance.avgPerformanceUsefulScore
                      ),
                      fill: getRadialColor(
                        competitors[2].performance.avgPerformanceUsefulScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '589.736px',
                  top: '476.5px',
                }}
                data-name='shape-5b0fd8'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[0].performance.avgPerformanceUsableScore
                      ),
                      fill: getRadialColor(
                        competitors[0].performance.avgPerformanceUsableScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '749.737px',
                  top: '476.5px',
                }}
                data-name='shape-622751'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[1].performance.avgPerformanceUsableScore
                      ),
                      fill: getRadialColor(
                        competitors[1].performance.avgPerformanceUsableScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '909.737px',
                  top: '476.5px',
                }}
                data-name='shape-d28e78'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[2].performance.avgPerformanceUsableScore
                      ),
                      fill: getRadialColor(
                        competitors[2].performance.avgPerformanceUsableScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '423.75px',
                  top: '476.5px',
                }}
                data-name='shape-6f9ca2'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(client.avgPerformanceUsableScore),
                      fill: getRadialColor(client.avgPerformanceUsableScore),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '113.093px',
                  left: '231.407px',
                  top: '294.827px',
                }}
                data-name='text-218046'
              >
                <div
                  className='sl-block-content'
                  style={{
                    zIndex: 51,
                    color: 'rgb(102, 102, 102)',
                    textAlign: 'left',
                  }}
                  dir='ui'
                >
                  <p style={{ fontSize: '20px' }}>
                    <span style={{ fontSize: '0.7em' }}>Is it happening?</span>
                  </p>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '160px',
                  left: '229px',
                  top: '367.154px',
                }}
                data-name='text-703de3'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
                    <span style={{ fontSize: '19px' }}>Efficiency</span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '113.093px',
                  left: '231.407px',
                  top: '395.154px',
                }}
                data-name='text-06e1a2'
              >
                <div
                  className='sl-block-content'
                  style={{
                    zIndex: 53,
                    color: 'rgb(102, 102, 102)',
                    textAlign: 'left',
                  }}
                  dir='ui'
                >
                  <p style={{ fontSize: '20px' }}>
                    <span style={{ fontSize: '0.7em' }}>Is it useful?</span>
                  </p>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '160px',
                  left: '229px',
                  top: '477.827px',
                }}
                data-name='text-16154b'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
                    <span style={{ fontSize: '19px' }}>Usability</span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '113.093px',
                  left: '231.407px',
                  top: '508.827px',
                }}
                data-name='text-be179c'
              >
                <div
                  className='sl-block-content'
                  style={{
                    zIndex: 55,
                    color: 'rgb(102, 102, 102)',
                    textAlign: 'left',
                  }}
                  dir='ui'
                >
                  <p style={{ fontSize: '20px' }}>
                    <span style={{ fontSize: '0.7em' }}>Is it usable?</span>
                  </p>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '160px',
                  left: '229px',
                  top: '590.999px',
                }}
                data-name='text-c21b39'
              >
                <div
                  className='sl-block-content'
                  style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
                  data-has-line-height
                  dir='ui'
                >
                  <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
                    <span style={{ fontSize: '19px' }}>Experience</span>
                  </h2>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='text'
                style={{
                  height: 'auto',
                  width: '113.093px',
                  left: '231.407px',
                  top: '623.999px',
                }}
                data-name='text-e1e43d'
              >
                <div
                  className='sl-block-content'
                  style={{
                    zIndex: 57,
                    color: 'rgb(102, 102, 102)',
                    textAlign: 'left',
                  }}
                  dir='ui'
                >
                  <p style={{ fontSize: '20px' }}>
                    <span style={{ fontSize: '0.7em' }}>Is it delightful?</span>
                  </p>
                </div>
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '589.736px',
                  top: '591.345px',
                }}
                data-name='shape-972bd5'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[0].performance.avgPerformanceDelightfulScore
                      ),
                      fill: getRadialColor(
                        competitors[0].performance.avgPerformanceDelightfulScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '423.75px',
                  top: '591.345px',
                }}
                data-name='shape-bbe8b5'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(client.avgPerformanceDelightfulScore),
                      fill: getRadialColor(
                        client.avgPerformanceDelightfulScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '749.736px',
                  top: '591.345px',
                }}
                data-name='shape-a36169'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[1].performance.avgPerformanceDelightfulScore
                      ),
                      fill: getRadialColor(
                        competitors[1].performance.avgPerformanceDelightfulScore
                      ),
                    },
                  ]}
                />
              </div>
              <div
                className='sl-block'
                data-block-type='shape'
                style={{
                  width: '78.5289px',
                  height: '72.655px',
                  left: '909.736px',
                  top: '591.345px',
                }}
                data-name='shape-2e7f6f'
              >
                <RadialChart
                  data={[
                    {
                      pct: Math.round(
                        competitors[2].performance.avgPerformanceDelightfulScore
                      ),
                      fill: getRadialColor(
                        competitors[2].performance.avgPerformanceDelightfulScore
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </section>
  );
};

export default SlideFour;
