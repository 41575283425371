const SlideFive = () => {
  return (
    <section data-background-image='https://s3.amazonaws.com/media-p.slid.es/uploads/2193027/images/9741830/BG_ThankYou_progressive.jpg'>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ width: 450, left: 80, top: 225, height: 'auto' }}
        data-name='text-46ac1f'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
          data-has-line-height
          dir='ui'
        >
          <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
            <span style={{ fontSize: '22px' }}>Milliseconds make millions</span>
          </h2>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ width: '424.5px', left: '570.5px', top: 495, height: 'auto' }}
        data-name='text-1c323a'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 11, fontSize: '158%', lineHeight: '1.04' }}
          data-has-line-height
          dir='ui'
        >
          <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
            <span style={{ fontSize: '22px' }}>
              What we used to gather your results
            </span>
          </h2>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ height: 'auto', width: 400, left: 80, top: 263 }}
        data-name='text-f191b6'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 12, textAlign: 'left' }}
          dir='ui'
        >
          <p style={{ fontSize: '20px' }}>
            <span style={{ color: 'rgb(0, 0, 0)', textAlign: 'justify' }}>
              To stay ahead, brands need to make site speed a priority across
              the organization. They should adopt a mobile-first mind-set;
              introducing the right processes and allocating resources to
              constantly monitor and optimize their site speed.
            </span>
          </p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ width: 649, left: 80, top: 144, height: 'auto' }}
        data-name='text-5fd4c7'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 13, fontSize: '158%', lineHeight: '1.04' }}
          data-has-line-height=''
          dir='ui'
        >
          <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
            <span style={{ fontSize: '34px' }}>Performance matters</span>
          </h2>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='image'
        data-name='image-763878'
        style={{
          width: '293.301px',
          height: 167,
          left: '121.204px',
          top: '504.5px',
          minWidth: 1,
          minHeight: 1,
        }}
      >
        <div className='sl-block-content' style={{ zIndex: 14 }}>
          <img
            style={{ left: '-22px', top: '-40px', width: 338, height: 215 }}
            data-natural-width={862}
            data-natural-height={548}
            data-lazy-loaded=''
            data-crop-x='0.065089'
            data-crop-y='0.186047'
            data-crop-width='0.867257'
            data-crop-height='0.776744'
            data-src='https://s3.amazonaws.com/media-p.slid.es/uploads/2193027/images/9741834/pasted-from-clipboard.png'
          />
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ height: 'auto', width: 400, left: '570.5px', top: 536 }}
        data-name='text-a6700c'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 15, textAlign: 'left' }}
          dir='ui'
        >
          <p style={{ fontSize: '20px' }}>
            We used Benchmarks, Deloitte’s internally developed product that
            scans your websites content approachability, performance and data
            ownership across web and mobile.
          </p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='image'
        data-name='image-881546'
        style={{
          width: '340.356px',
          height: '130px',
          left: '570.5px',
          top: 256,
          minWidth: 1,
          minHeight: 1,
        }}
      >
        <div
          className='sl-block-style'
          style={{
            zIndex: 16,
            filter: 'drop-shadow(rgb(204, 204, 204) 0px 2px 2px) opacity(100%)',
          }}
        >
          <div className='sl-block-content' style={{ zIndex: 16 }}>
            <img
              style={{}}
              data-natural-width={1618}
              data-natural-height={618}
              data-lazy-loaded
              data-src='https://s3.amazonaws.com/media-p.slid.es/uploads/2193027/images/9741835/pasted-from-clipboard.png'
            />
          </div>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='image'
        data-name='image-cc85e2'
        style={{
          width: '82.4071px',
          height: '38.018px',
          left: 80,
          top: 72,
          minWidth: 1,
          minHeight: 1,
        }}
      >
        <div className='sl-block-content' style={{ zIndex: 17 }}>
          <img
            style={{}}
            data-natural-width={789}
            data-natural-height={364}
            data-lazy-loaded=''
            data-src='https://s3.amazonaws.com/media-p.slid.es/uploads/2193027/images/9741802/DEL_PRI_Digital_RGB.png'
          />
        </div>
      </div>
    </section>
  );
};

export default SlideFive;
