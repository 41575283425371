import { FormControl, Grid, TextField, Typography } from '@mui/material';
import { AdminToolTip } from '@deloitte-us-consulting-dd/blueprint-ux-admin';

const NewDashDetial = ({ formik }) => {
  return (
    <>
      <Typography variant='h4' style={{ flex: 1 }}>
        Select Dashboard Name & Description
      </Typography>
      <form>
        <FormControl
          margin='dense'
          fullWidth
          style={{ paddingTop: 20, paddingBottom: 20 }}
        >
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Typography variant='subtitle1'>
                Enter a name
                <AdminToolTip
                  message={
                    <Typography variant='caption'>
                      <strong>Name</strong> can be helpful to provide an
                      admin-only label for future reference or referral. <br />
                      <br /> Typically, this could be a version number (e.g.
                      v1.0.0) or report name
                    </Typography>
                  }
                ></AdminToolTip>
              </Typography>
              <TextField
                error={formik.errors.dashboardName}
                helperText={formik.errors.dashboardName}
                id='dashboardName'
                name='dashboardName'
                type='text'
                variant='outlined'
                label='Dashboard Name'
                required
                defaultValue={formik.values.dashboardName}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl
          margin='dense'
          fullWidth
          style={{ paddingTop: 20, paddingBottom: 20 }}
        >
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Typography variant='subtitle1'>
                Enter a desciption
                <AdminToolTip
                  message={
                    <Typography variant='caption'>
                      <strong>Description</strong> can be helpful to provide an
                      admin-only label for future reference or referral. <br />
                      <br /> Typically, this is just a descriptor to help you
                      differentiate your different dashboards
                    </Typography>
                  }
                ></AdminToolTip>
              </Typography>
              <TextField
                error={formik.errors.dashboardDescription}
                helperText={formik.errors.dashboardDescription}
                id='dashboardDescription'
                name='dashboardDescription'
                type='text'
                variant='outlined'
                label='Dashboard Description'
                defaultValue={formik.values.dashboardDescription}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
        </FormControl>
      </form>
    </>
  );
};

export default NewDashDetial;
