import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useBlueprintAdmin,
  AdminPage,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import DashboardPage from './DashboardPage';
const CustomDashboard = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [dashboard, SetDashboard] = useState<any>(null);

  const { config, jwtToken, adminDashboardApi } = useBlueprintAdmin();

  useEffect(() => {
    adminDashboardApi!
      .getDashboard(id!)
      .then(({ data }) => {
        SetDashboard(data);
      })
      .catch((e) => console.error(e));
  }, []);
  return (
    <AdminPage
      animation={true}
      title={dashboard?.name}
      newAction={`/reports/querybuilder/${dashboard ? dashboard?.id : ''}`}
      newActionText='New Dashboard Tile'
    >
      <DashboardPage dashBoard={dashboard}></DashboardPage>
    </AdminPage>
  );
};

export default CustomDashboard;
