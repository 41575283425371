import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useBlueprintAdmin,
  AdminBaseForm,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';

const BenchmarkSectorsNew = () => {
  const [industries, setIndustries] = useState<Array<any>>([]);
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { config, adminBenchmarkApi } = useBlueprintAdmin();

  useEffect(() => {
    adminBenchmarkApi!
      .getIndustries()
      .then(({ data }) => {
        setIndustries(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const onSuccess = (response) => {
    navigate(`/benchmarks/sectors`);
  };

  const uiSchema = {
    industryId: { 'ui:widget': 'autoComplete' },
  };

  const schema = {
    properties: {
      name: {
        type: 'string',
      },
      industryId: {
        type: 'string',
        title: 'Industry',
        enum: industries.map((industry: any) => industry.id),
        enumNames: industries.map((industry: any) => industry.name),
        description: '',
      },
    },
    type: 'object',
    required: ['name', 'industryId'],
  };

  return (
    <AdminBaseForm
      url='/admin/benchmarks/sectors'
      entityTitle='Sectors'
      schema={schema}
      uiSchema={uiSchema}
      onSuccess={onSuccess}
    />
  );
};

export default BenchmarkSectorsNew;
