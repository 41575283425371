import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminBenchmarkExperienceRun from '../../../components/AdminBenchmarkExperienceRun';
import AdminBenchmarkPerformanceRun from '../../../components/AdminBenchmarkPerformanceRun';
import AdminBenchmarkSearchRun from '../../../components/AdminBenchmarkSearchRun';
import AdminBenchmarkTechnologyRun from '../../../components/AdminBenchmarkTechnologyRun';
import {
  useBlueprintAdmin,
  AdminPage,
  AdminRecord,
  AdminTabs,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import { AdminBenchmarkAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-benchmark';

const BenchmarkClientRunDetail = () => {
  const { clientId, runId } = useParams<{ clientId: string; runId: string }>();
  const { config, jwtToken } = useBlueprintAdmin();
  const [client, setClient] = useState<any>(undefined);
  const adminBenchmarkApi = new AdminBenchmarkAPI(config, jwtToken);

  const [runDetail, setRunDetail] = useState(undefined);
  const navigate = useNavigate();

  const getClient = async () => {
    const response = await adminBenchmarkApi!.getClient(clientId!);
    if (response.success) {
      setClient(response.data);
      console.log(response.data);
    }
  };

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    if (clientId) {
      getClient().catch((e) => {
        console.error(e);
      });
    }
  }, [runId, jwtToken]);

  interface AdminTab {
    title: string;
    template: React.ReactNode;
    icon?: React.ReactNode;
  }

  const experienceTabItem = (page) => {
    return {
      title: `${page.page.identifier} - ${page.properties.deviceType}`,
      template: (
        <>
          <AdminBenchmarkExperienceRun data={page} />
        </>
      ),
    };
  };

  const performanceTabItem = (page) => {
    return {
      title: `${page.page.identifier} - ${page.properties.deviceType}`,
      template: <AdminBenchmarkPerformanceRun data={page} />,
    };
  };

  const technologyTabItem = (page) => {
    return {
      title: `Technology`,
      template: <AdminBenchmarkTechnologyRun data={page} />,
    };
  };

  const searchTabItem = (page) => {
    return {
      title: `Search`,
      template: <AdminBenchmarkSearchRun data={page} />,
    };
  };

  const getTabs = (pages) => {
    const tabs: Array<AdminTab> = [];

    for (const page of pages) {
      if (page.pass === 'experience') {
        tabs.push(experienceTabItem(page));
      }
      if (page.pass === 'performance') {
        tabs.push(performanceTabItem(page));
      }
      if (page.pass === 'technology') {
        tabs.push(technologyTabItem(page));
      }
      if (page.pass === 'search') {
        tabs.push(searchTabItem(page));
      }
    }

    return tabs;
  };

  const itemTemplate = (data) => (
    <>
      <AdminTabs tabs={getTabs(data.events)} />
    </>
  );

  const collectPerformanceRun = async () => {
    const response = await adminBenchmarkApi!.collectPerformanceRun(
      clientId!,
      runId!
    );

    if (response.success) {
      history.go(0);
    }
  };

  return (
    <>
      {clientId && runId && client && (
        <AdminPage
          animation={true}
          title={client.name}
          breadcrumbs={[
            { href: '/benchmarks/clients', label: 'Benchmarks' },
            { href: '/benchmarks/clients', label: 'Clients' },
            {
              href: `/benchmarks/clients/${clientId}`,
              label: client.name,
            },
            {
              label: `Runs`,
            },
            {
              label: runId,
            },
          ]}
        >
          <AdminRecord
            form={false}
            url={`/admin/benchmarks/clients/${clientId}/runs/${runId}`}
            itemTemplate={itemTemplate}
          />
        </AdminPage>
      )}
    </>
  );
};

export default BenchmarkClientRunDetail;
