import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Grid, TableCell, Typography, Link as UiLink } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Link, useLocation } from 'react-router-dom';
import {
  uuidv4,
  AdminToastTimed,
  AdminPaginatedList,
  AdminPage,
  AdminTableRow,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    paddingBottom: theme.spacing(2),
  },
  sourceText: {
    fontSize: '11px',
    lineHeight: '15px',
    letterSpacing: '.15px',
    marginTop: '5px',
  },
}));

const ConfigCustomDashList = () => {
  const [toastController, setToastController]: any = useState(null);
  const classes = useStyles();
  const location: any = useLocation();

  useEffect(() => {
    setToastController(location.state?.detail ?? '');
  }, [location.state?.detail]);

  const configTemplate = ({ id, name, updatedAt, description }, index) => {
    return (
      <AdminTableRow key={index}>
        <TableCell>
          <UiLink component={Link} to={`/reports/customdashboard/${id}`}>
            {name}
          </UiLink>
        </TableCell>
        <TableCell>
          <Typography variant='body2'>{id}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant='body2'>{description}</Typography>
        </TableCell>
        <TableCell>
          <Moment fromNow>{updatedAt}</Moment>
        </TableCell>
        <TableCell>
          <UiLink
            color='primary'
            component={Link}
            to={`/reports/customdashboard/${id}`}
            style={{ marginLeft: '40px' }}
          >
            <EditOutlinedIcon />
          </UiLink>
        </TableCell>
      </AdminTableRow>
    );
  };

  return (
    <>
      {toastController?.includes('BeaconConverted') && (
        <AdminToastTimed
          message={
            'Beacon successfully Converted, Install the beacon to start collecting data'
          }
          trigger={
            toastController?.includes('BeaconConverted') ? uuidv4() : null
          }
          time={3000}
          severity={'success'}
        ></AdminToastTimed>
      )}
      <AdminPage
        animation={true}
        title='Custom Dashboards'
        newAction='/reports/newdashboard'
      >
        <Grid className={classes.card}>
          <AdminPaginatedList
            columns={[
              'Dashboard Name',
              'Dashboard ID',
              'Dashboard Description',
              'Last Updated',
              '',
            ]}
            url='/admin/dashboards'
            itemTemplate={configTemplate}
          />
        </Grid>
      </AdminPage>
    </>
  );
};

export default ConfigCustomDashList;
