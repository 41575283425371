import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminClientBaseForm from '../../../../components/AdminClientBaseForm';
import { useBlueprintAdmin } from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import { AdminBenchmarkAPI } from '@deloitte-us-consulting-dd/blueprint-sdk-benchmark';

const BenchmarkClientPageEdit = () => {
  const { config, jwtToken } = useBlueprintAdmin();
  const [formData, setFormData] = useState<any>({});
  const { clientId, pageId } = useParams<{
    clientId: string;
    pageId: string;
  }>();
  const navigate = useNavigate();
  const adminBenchmarkApi = new AdminBenchmarkAPI(config, jwtToken);

  const onSuccess = (response) => {
    navigate(`/benchmarks/clients/${clientId}`);
  };

  const getClientPage = async () => {
    const response = await adminBenchmarkApi!.getPage(clientId!, pageId!);

    const fields = response.data.page;

    setFormData({
      id: fields.id,
      name: fields.name,
      url: fields.url,
      identifier: fields.identifier ? fields.identifier : undefined,
    });
  };

  useEffect(() => {
    getClientPage().catch((e) => {
      console.error(e);
    });
  }, []);

  return (
    <>
      {pageId && (
        <AdminClientBaseForm
          url={`/admin/benchmarks/clients/${clientId}/pages/`}
          id={pageId}
          entityTitle='Client Pages'
          onSuccess={onSuccess}
          description={undefined}
          formData={formData}
          breadcrumbs={[
            { href: '/benchmarks/clients', label: 'Benchmarks' },
            { href: '/benchmarks/clients', label: 'Clients' },
            {
              href: `/benchmarks/clients/${clientId}`,
              label: clientId,
              // TODO - Client name not available in scope, need to pull through, using clientId as a fallback
            },
            {
              label: 'Edit',
            },
          ]}
          adminClientPage={true}
        />
      )}
    </>
  );
};

export default BenchmarkClientPageEdit;
