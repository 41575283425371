import { Link } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  useFetch,
  useBlueprintAdmin,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';

function AdminBenchmarkIndustryList({ data }: any) {
  const { config, jwtToken } = useBlueprintAdmin();
  const [industries, setIndustries] = useState<any>([]);

  const { fetchData } = useFetch({
    url: `${config.apiRoot}/admin/benchmarks/industries`,
    options: {
      method: 'get',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    },
    onSuccess: ({ data }) => {
      setIndustries(data);
    },
  });

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    fetchData().catch((e) => {
      console.error(e);
    });
  }, [jwtToken]);

  return (
    <div style={{ marginBottom: 10 }}>
      Browse By Industry: {` `}
      {industries &&
        industries.map((record, index) => (
          <Link
            style={{ marginRight: 8 }}
            key={index}
            href={`/benchmarks/browse/industry/${record.name}`}
          >
            {`${record.name}`}
          </Link>
        ))}
    </div>
  );
}

export default AdminBenchmarkIndustryList;
