import moment from 'moment';
import Moment from 'react-moment';

const SlideOne = ({ client }) => {
  return (
    <section data-background-image='https://s3.amazonaws.com/media-p.slid.es/uploads/2193027/images/9744735/BG_Slide2_progressive2.jpg'>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ width: '404px', left: '80px', top: '442px', height: 'auto' }}
      >
        <div
          className='sl-block-content'
          style={{
            zIndex: 10,
            lineHeight: 1.04,
          }}
          dir='ui'
          data-has-line-height=''
        >
          <h1 style={{ textAlign: 'left', fontSize: '40px' }}>
            <span style={{ fontSize: '38px' }}>Site Performance</span>
          </h1>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='line'
        data-name='line-a5c0c7'
        style={{
          width: 'auto',
          height: 'auto',
          left: '78px',
          top: '503px',
        }}
      >
        <div
          className='sl-block-content'
          data-line-x1='0'
          data-line-y1='0'
          data-line-x2='314'
          data-line-y2='0'
          data-line-color='#000000'
          data-line-start-type='none'
          data-line-end-type='none'
          style={{ zIndex: 11 }}
          data-line-width='3px'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            version='1.1'
            preserveAspectRatio='xMidYMid'
            width='314'
            height='1'
            viewBox='0 0 314 1'
          >
            <line
              stroke='rgba(0,0,0,0)'
              strokeWidth='15'
              x1='0.5'
              y1='0.5'
              x2='314.5'
              y2='0.5'
            ></line>
            <line
              className='line-element'
              stroke='#000000'
              strokeWidth='3'
              x1='0.5'
              y1='0.5'
              x2='314.5'
              y2='0.5'
            ></line>
          </svg>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ width: '470px', left: '59px', top: '504px', height: 'auto' }}
        data-name='text-d18e72'
      >
        <div
          className='sl-block-content'
          style={{
            zIndex: 12,
            textAlign: 'left',
            color: 'rgb(0, 0, 0)',
            fontSize: '120%',
            padding: '20px',
          }}
        >
          <p style={{ fontSize: '20px' }}>
            {client.name}, {<Moment format='MMMM YYYY'>{moment()}</Moment>}
          </p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='image'
        data-name='image-cc85e2'
        style={{
          width: '82.4071px',
          height: '38.018px',
          left: '80px',
          top: '72px',
          minWidth: '1px',
          minHeight: '1px',
        }}
      >
        <div className='sl-block-content' style={{ zIndex: 13 }}>
          <img
            data-natural-width='789'
            data-natural-height='364'
            data-lazy-loaded=''
            data-src='https://s3.amazonaws.com/media-p.slid.es/uploads/2193027/images/9741802/DEL_PRI_Digital_RGB.png'
          />
        </div>
      </div>
    </section>
  );
};

export default SlideOne;
