import { useEffect, useState } from 'react';
import { AdminAssetViewer } from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import AdminBenchmarkPerformanceChart from '../../../../../components/AdminBenchmarkPerformanceChart';
import RadialChart from '../../../../../components/reporting/RadialChart';
import {
  getPerformanceStatus,
  getRadialColor,
} from '../../../../../pages/benchmarks/utils';
const SlideTwo = ({ client, industryClients, clientPages }) => {
  const [homePage, setHomePage] = useState<any>(undefined);
  useEffect(() => {
    if (clientPages) {
      console.log(clientPages);

      const screenshot = clientPages.filter((page) => {
        return page.name === 'Home Page' || page.name === 'Home';
      });
      setHomePage(screenshot[0]);
    }
  }, [clientPages]);

  return (
    <section data-background-image='https://s3.amazonaws.com/media-p.slid.es/uploads/2193027/images/9741325/BG_Slide3_progressive.jpg'>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ width: 649, left: '75.5px', top: 144, height: 'auto' }}
        data-name='text-46ac1f'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 10, fontSize: '158%', lineHeight: '1.04' }}
          data-has-line-height=''
          dir='ui'
        >
          <h2 style={{ textAlign: 'left', fontSize: '30px' }}>
            <span style={{ fontSize: '34px' }}>
              Here is what we found about your site's performance
            </span>
          </h2>
        </div>
      </div>
      {/* screenshot */}
      <div
        className='sl-block'
        data-block-type='shape'
        data-name='shape-2e5fb6'
        style={{
          width: '526.253px',
          height: '420.154px',
          left: '696.873px',
          top: 244,
        }}
      >
        {homePage && (
          <AdminAssetViewer id={homePage.screenshot} clickable={false} />
        )}
      </div>
      <div
        className='sl-block sl-block-group'
        data-block-type='group'
        data-name='group-d2fedf'
        style={{ height: 'auto' }}
      >
        <div
          className='sl-block-content sl-block-group-content'
          style={{ transformOrigin: '312.073px 454.154px', zIndex: 13 }}
        >
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              height: 'auto',
              width: '378.081px',
              left: '130.959px',
              top: '369.894px',
            }}
            data-name='text-dda9a6'
          >
            <div className='sl-block-content' style={{ zIndex: 14 }} dir='ui'>
              <p style={{ fontSize: '20px' }}>
                <span style={{ fontSize: '1em' }}>
                  {`Your website's performance ${getPerformanceStatus(
                    client.avgPerformanceScore
                  )}`}
                </span>
              </p>
            </div>
          </div>
          {/* radial chart */}
          <div
            className='sl-block'
            data-block-type='shape'
            style={{
              width: '131.324px',
              height: '121.501px',
              left: '244.312px',
              top: '244.154px',
            }}
            data-name='shape-79fb8c'
          >
            <RadialChart
              data={[
                {
                  pct: Math.round(client.avgPerformanceScore),
                  fill: getRadialColor(client.avgPerformanceScore),
                },
              ]}
            />
          </div>
          {/* slider */}

          <div
            className='sl-block'
            data-block-type='shape'
            style={{
              width: '394.662px',
              height: '125.731px',
              left: '136.7425px',
              top: '477.337px',
            }}
            data-name='shape-336f05'
          >
            <AdminBenchmarkPerformanceChart
              clientValue={client.avgPerformanceScore}
              competitorAvg={parseFloat(industryClients.avgPerformanceScore)}
              min={industryClients.minPerformanceScore}
              max={industryClients.maxPerformanceScore}
              variant={'industry'}
            />
          </div>
          <div
            className='sl-block'
            data-block-type='text'
            style={{
              height: 'auto',
              width: '378.081px',
              left: '130.959px',
              top: '580.154px',
            }}
            data-name='text-d2a23d'
          >
            <div className='sl-block-content' style={{ zIndex: 19 }} dir='ui'>
              <p style={{ fontSize: '20px' }}>
                Your performance compared to your industry
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='text'
        style={{ height: 'auto', width: 729, left: '275.5px', top: 694 }}
        data-name='text-e7473b'
      >
        <div
          className='sl-block-content'
          style={{ zIndex: 21, color: 'rgb(102, 102, 102)', textAlign: 'left' }}
          dir='ui'
        >
          <p style={{ textAlign: 'center', fontSize: '20px' }}>
            <span style={{ fontSize: '0.5em' }}>
              Values are estimates and may vary. Report was conducted using lab
              and google CrUx data.
            </span>
          </p>
        </div>
      </div>
      <div
        className='sl-block'
        data-block-type='image'
        data-name='image-cc85e2'
        style={{
          width: '82.4071px',
          height: '38.018px',
          left: 80,
          top: 72,
          minWidth: 1,
          minHeight: 1,
        }}
      >
        <div className='sl-block-content' style={{ zIndex: 22 }}>
          <img
            style={{}}
            data-natural-width={789}
            data-natural-height={364}
            data-lazy-loaded=''
            data-src='https://s3.amazonaws.com/media-p.slid.es/uploads/2193027/images/9741802/DEL_PRI_Digital_RGB.png'
          />
        </div>
      </div>
    </section>
  );
};

export default SlideTwo;
