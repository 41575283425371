import { Grid, Typography } from '@mui/material';
import {
  AdminAssetViewer,
  AdminAssetLink,
  AdminCard,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import BenchmarkNumber from './AdminBenchmarkNumber';

function AdminBenchmarkExperienceOverview({ data }) {
  return (
    <>
      <AdminCard
        title={data.page.name}
        customAction={
          data.properties.assets.source.html && (
            <AdminAssetLink
              id={data.properties.assets.source.html}
              caption='Download Source HTML'
            />
          )
        }
      >
        <Typography variant='caption'>{data.page.url}</Typography>
        <Grid container item xs={12} spacing={5} style={{ paddingTop: 40 }}>
          <Grid
            container
            item
            xs={12}
            style={{ backgroundColor: 'lightyellow' }}
          >
            <Grid container item xs={12} spacing={5}>
              <BenchmarkNumber
                title='Experience Score'
                value={data.experienceBenchmark.experienceScore}
                caption='This is the overall score (accessibility + readability)'
              />
              <BenchmarkNumber
                title='Accessibility Score'
                value={data.experienceBenchmark.accessibilityScore}
                caption='This is the total score for accessibility'
              />
              <BenchmarkNumber
                title='Readability Score'
                value={data.experienceBenchmark.readabilityScore}
                caption='This is the total score for readability'
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {data.properties.assets.screenshot && (
              <AdminAssetViewer
                id={data.properties.assets.screenshot}
                title='Screenshot'
              />
            )}
            {!data.properties.assets.screenshot && (
              <p>Screenshot was unable to be collected</p>
            )}
          </Grid>
        </Grid>
      </AdminCard>
    </>
  );
}

export default AdminBenchmarkExperienceOverview;
