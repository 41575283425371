// in order to allow this file to work, you will need to place this in your package.json
//
// "@cubejs-client/playground": "^0.29.8",
// "antd": "^4.18.2",
// "styled-components": "^5.3.3",
// "core-js": "^3.20.2"
// import { QueryBuilder } from '@cubejs-client/playground';
// import '@cubejs-client/playground/lib/antd.min.css';
// import { useLocation } from 'react-router-dom';
import 'antd/dist/antd.css';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AdminPage,
  useBlueprintAdmin,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';
import QueryBuildFunction from './queryBuilder';
import NameForm from './queryName';

export interface DashboardItem {
  id: string;
  name: string;
  vizState: object;
}

export default function QueryExplorer() {
  const { id } = useParams<{ id: string }>();
  const { itemid } = useParams<{ itemid: string }>();
  const { config, adminDashboardApi } = useBlueprintAdmin();
  const [vizState, setVizState] = useState({});
  const [editItem, setEditItem] = useState<DashboardItem | undefined>(
    undefined
  );
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: (values) => {
      // do nothing
    },
  });

  useEffect(() => {
    if (itemid) {
      // this means we are edditing an item dashboard
      adminDashboardApi!
        .getDashboard(id!)
        .then(({ data }) => {
          const currentViz = data.items.filter((value) => value.id === itemid);
          console.error(currentViz[0].vizState);

          void formik.setFieldValue('name', currentViz[0].name);
          setVizState(currentViz[0].vizState);
          setEditItem(currentViz[0]);
        })
        .catch((e) => console.error(e));
    }
  }, [itemid]);

  const addQueryToDashboard = () => {
    if (editItem != undefined) {
      // we are doing the patch command because we are editing
      editItem.vizState = vizState;
      editItem.name = formik.values.name;
      // updateDashboardItem
      console.log('edit item');
      adminDashboardApi!
        .updateDashboardItem(id!, { items: [editItem] })
        .then((data) => {
          console.log(data);
        })
        .catch((e) => console.error(e));
    } else {
      // we are doing a post because we are making a new one
      console.log('create item');
      if (id) {
        adminDashboardApi!
          .createDashboardItem(id, {
            name: formik.values.name,
            vizState: vizState,
            layout: {
              x: 0,
              y: 0,
              w: 24,
              h: 8,
            },
          })
          .then((data) => {
            console.log(data);
          })
          .catch((e) => console.error(e));
      }
    }
    navigate(`/reports/customdashboard/${id}`);
  };

  return (
    <>
      <AdminPage
        animation={true}
        title='Create or Edit New Dashboard Tile'
        customActionText={
          editItem != undefined
            ? 'Update Dashboard Tile'
            : 'Create Dashboard Tile'
        }
        customAction={addQueryToDashboard}
      >
        <NameForm formik={formik} />
        <QueryBuildFunction vizState={vizState} setVizState={setVizState} />
      </AdminPage>
    </>
  );
}
