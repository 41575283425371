import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material';
import {
  AdminCard,
  AdminTableHead,
  AdminTableHeadCell,
  AdminTableRow,
} from '@deloitte-us-consulting-dd/blueprint-ux-admin';

function AdminBenchmarkTechnologyRun({ data }) {
  const trackerMap: Map<any, Array<any>> = new Map();

  const formatTitle = (title) => {
    let val = title.replace('||', '');
    if (val.indexOf('^')) {
      val = val.split('^')[0];
    }
    if (val.indexOf('/')) {
      val = val.split('/')[0];
    }
    return val;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <AdminCard title={`Data Collection Counts`}>
          <Table>
            <AdminTableHead>
              <TableRow>
                <AdminTableHeadCell>Type</AdminTableHeadCell>
                <AdminTableHeadCell>Count</AdminTableHeadCell>
              </TableRow>
            </AdminTableHead>
            <TableBody>
              <AdminTableRow>
                <TableCell>1st Party Domains</TableCell>
                <TableCell>
                  {data.properties.hosts.requests.first_party.length}
                </TableCell>
              </AdminTableRow>
              <AdminTableRow>
                <TableCell>3rd Party Domains</TableCell>
                <TableCell>
                  {data.properties.hosts.requests.third_party.length}
                </TableCell>
              </AdminTableRow>
              <AdminTableRow>
                <TableCell>Cookies</TableCell>
                <TableCell>{data.properties.reports.cookies.length}</TableCell>
              </AdminTableRow>
              <AdminTableRow>
                <TableCell>3rd Party Events Tracked</TableCell>
                <TableCell>
                  {data.properties.reports.third_party_trackers.length}
                </TableCell>
              </AdminTableRow>
            </TableBody>
          </Table>
        </AdminCard>
      </Grid>
      <Grid item xs={6}>
        <AdminCard
          title={`Pages Scanned (${data.properties.browsing_history.length})`}
        >
          <Table>
            <AdminTableHead>
              <TableRow>
                <AdminTableHeadCell>Domain</AdminTableHeadCell>
              </TableRow>
            </AdminTableHead>
            <TableBody>
              {data.properties.browsing_history.map((page, index) => (
                <AdminTableRow key={index}>
                  <TableCell>{page}</TableCell>
                </AdminTableRow>
              ))}
            </TableBody>
          </Table>
        </AdminCard>

        <AdminCard
          title={`1st Party Domains (${data.properties.hosts.requests.first_party.length})`}
        >
          <Table>
            <AdminTableHead>
              <TableRow>
                <AdminTableHeadCell>Domain</AdminTableHeadCell>
              </TableRow>
            </AdminTableHead>
            <TableBody>
              {data.properties.hosts.requests.first_party.map(
                (domain, index) => (
                  <AdminTableRow key={index}>
                    <TableCell>{domain}</TableCell>
                  </AdminTableRow>
                )
              )}
            </TableBody>
          </Table>
        </AdminCard>

        <AdminCard
          title={`3rd Party Domains (${data.properties.hosts.requests.third_party.length})`}
        >
          <Table>
            <AdminTableHead>
              <TableRow>
                <AdminTableHeadCell>Domain</AdminTableHeadCell>
              </TableRow>
            </AdminTableHead>
            <TableBody>
              {data.properties.hosts.requests.third_party.map(
                (domain, index) => (
                  <AdminTableRow key={index}>
                    <TableCell>{domain}</TableCell>
                  </AdminTableRow>
                )
              )}
            </TableBody>
          </Table>
        </AdminCard>
      </Grid>

      <Grid item xs={6}>
        <AdminCard
          title={`Cookies (${data.properties.reports.cookies.length})`}
        >
          <Table>
            <AdminTableHead>
              <TableRow>
                <AdminTableHeadCell>Domain</AdminTableHeadCell>
                <AdminTableHeadCell>Cookie</AdminTableHeadCell>
              </TableRow>
            </AdminTableHead>
            <TableBody>
              {data.properties.reports.cookies.map((cookie, index) => (
                <AdminTableRow key={index}>
                  <TableCell>{cookie.domain}</TableCell>
                  <TableCell>{cookie.name}</TableCell>
                </AdminTableRow>
              ))}
            </TableBody>
          </Table>
        </AdminCard>

        <AdminCard
          title={`3rd Party Events Tracked (${data.properties.reports.third_party_trackers.length})`}
        >
          <Table>
            <AdminTableHead>
              <TableRow>
                <AdminTableHeadCell>Domain</AdminTableHeadCell>
                <AdminTableHeadCell>Type</AdminTableHeadCell>
              </TableRow>
            </AdminTableHead>
            <TableBody>
              {data.properties.reports.third_party_trackers.map(
                (record, index) => (
                  <AdminTableRow key={index}>
                    <TableCell>
                      <>{formatTitle(record.data.filter)}</>
                    </TableCell>
                    <TableCell>{record.type}</TableCell>
                  </AdminTableRow>
                )
              )}
            </TableBody>
          </Table>
        </AdminCard>

        {/* <AdminCard
          title={`Event Listeners (${data.properties.reports.behaviour_event_listeners.length})`}
        ></AdminCard>

        <AdminCard
          title={`Canvas Fingerprinters (${data.properties.reports.canvas_fingerprinters.length})`}
        ></AdminCard>

        <AdminCard
          title={`Font Fingerprinters (${data.properties.reports.canvas_font_fingerprinters.length})`}
        ></AdminCard>

        <AdminCard
          title={`Pixel Events (${data.properties.reports.fb_pixel_events.length})`}
        ></AdminCard>

        <AdminCard
          title={`Key Loggers (${data.properties.reports.key_logging.length})`}
        ></AdminCard>

        <AdminCard
          title={`Session Recorders (${data.properties.reports.session_recorders.length})`}
        ></AdminCard>

        <AdminCard title='Other data available'>
          <p>
            Look in the devtools in the console for the data returned on this
            scan
          </p>
          <ul>
            <li>data.properties.reports.behaviour_event_listeners</li>
            <li>data.properties.reports.canvas_fingerprinters</li>
            <li>data.properties.reports.canvas_font_fingerprinters</li>
            <li>data.properties.reports.fb_pixel_events</li>
            <li>data.properties.reports.key_logging</li>
            <li>data.properties.reports.session_recorders</li>
            <li>data.properties.reports.third_party_trackers</li>
          </ul>
        </AdminCard> */}
      </Grid>
    </Grid>
  );
}

export default AdminBenchmarkTechnologyRun;
